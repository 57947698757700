// import { useMutation, useQueryClient } from "react-query";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from './api-client';

export const useLoginAdmin = () => {
  const queryClient = useQueryClient();
  const loginAdmin = async loginData => {
    const response = await client('auth/Login', {
      method: 'POST',
      data: loginData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    if (response.token) {
      localStorage.setItem('token', response.token);
      queryClient.invalidateQueries('token');
      return response;
    } else {
      throw new Error('Unexpected response from the server');
    }
  };

  const logout = () => {
    localStorage.removeItem('tokenIssueWarned');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    queryClient.invalidateQueries('token');
  };

  return useMutation(loginAdmin);
};

export const useCreateUser = () => {
  const registerUser = async registorData => {
    const response = await client('auth/Signup', {
      method: 'POST',
      data: registorData,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(registerUser);
};

export const useForGetPassword = () => {
  const forgetpassword = async forgetpasswordData => {
    const response = await client('auth/forgotPassword', {
      method: 'POST',
      data: forgetpasswordData,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(forgetpassword);
};

export const useVerifyOtp = () => {
  const verifyotp = async forgetpasswordData => {
    const response = await client('auth/verifyOtpForPasswordReset', {
      method: 'POST',
      data: forgetpasswordData,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(verifyotp);
};

export const useResetPassword = () => {
  const resetPassword = async forgetpasswordData => {
    const response = await client('auth/resetPassword', {
      method: 'POST',
      data: forgetpasswordData,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(resetPassword);
};

export const useStopWatchSet = () => {
  const queryClient = useQueryClient();
  const stopwatch = async stopwatchsetData => {
    const response = await client('auth/stopwatchSet', {
      method: 'POST',
      data: stopwatchsetData,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(stopwatch, {
    onSuccess: () => {
      // Invalidate specific queries after the mutation is successful
      queryClient.invalidateQueries(['useStopWatchGet']); // Replace with the actual key used in the query
    },
  });
};

export const useStopWatchGet = id => {
  return useQuery(
    ['useStopWatchGet', id],
    async () => {
      let queryString = `auth/stopwatchget/${id}`;
      const response = await client(queryString);

      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useAddDeviceToken = () => {
  const addDevice = async deviceToken => {
    const response = await client('auth/addDeviceToken', {
      method: 'POST',
      data: deviceToken,
    });

    console.log('response api', response);

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(addDevice);
};
