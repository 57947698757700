// import { useMutation, useQueryClient } from "react-query";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from './api-client';

export const useAddGoal = () => {
  const queryClient = useQueryClient();
  const addGoal = async goalData => {
    const response = await client('dashboard/addGoals', {
      method: 'POST',
      data: goalData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(addGoal, {
    onSuccess: () => {
      // Invalidate specific queries after the mutation is successful
      queryClient.invalidateQueries(['getAllGoalsOfCurrenrUser']); // Replace with the actual key used in the query
    },
  });
};

export const useGetAllGoals = () => {
  return useQuery(
    ['getAllGoals'],
    async () => {
      let queryString = `dashboard/getAllGoals`;
      const response = await client(queryString);
      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useGetAllOfCurrentUser = () => {
  return useQuery(
    ['getAllGoalsOfCurrenrUser'],
    async () => {
      let queryString = `dashboard/getAllGoalsOfCurrentUser`;
      const response = await client(queryString);

      return response;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useAddNoteToGoal = () => {
  const queryClient = useQueryClient();

  const addNoteToGoal = async goalData => {
    const response = await client('dashboard/addNoteToGoal', {
      method: 'POST',
      data: goalData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when adding note fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(addNoteToGoal, {
    onSuccess: () => {
      // Invalidate specific queries after the mutation is successful
      queryClient.invalidateQueries(['getAllGoalsOfCurrenrUser']); // Replace with the actual key used in the query
    },
  });
};

export const useUpdateGoal = () => {
  const queryClient = useQueryClient();

  const updateGoalById = async goalData => {
    const response = await client('dashboard/updateGoalById', {
      method: 'POST',
      data: goalData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when update fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(updateGoalById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAllGoalsOfCurrenrUser']);
    },
  });
};

export const useUpdateGoalTime = () => {
 
  const updatetime = async goalData => {
    const response = await client('dashboard/updateGoalTime', {
      method: 'POST',
      data: goalData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(updatetime);
};

export const useGetGoalTime = () => {
 
  const getGoalTime = async goalData => {
    const response = await client('dashboard/getGoalTime', {
      method: 'POST',
      data: goalData,
    });

    if (response.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response.error);
    }

    return response;
  };

  return useMutation(getGoalTime);
};


export const useGetLoggedInUser = () =>
   useQuery(
    ['getLoggedInUser'],
    async () => {
      const response = await client('auth/getLoggedInUser');
      return response;
    },
    {
      refetchInterval: 10000,
    }
  );

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async payload => {
      const response = await client(`auth/updateProfile`, {
        method: 'POST',
        data: payload,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getLoggedInUser']);
      },
    }
  );
};

export const useDeletGoalById = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async payload => {
      const response = await client(`dashboard/deletGoalById`, {
        method: 'POST',
        data: payload,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllGoalsOfCurrenrUser']);
      },
    }
  );
};

export const useDeletGoalNoteById = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async payload => {
      const response = await client(`dashboard/deleteGoalNoteById`, {
        method: 'POST',
        data: payload,
      });
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllGoalsOfCurrenrUser']);
      },
    }
  );
};
