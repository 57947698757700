import React from 'react';
import {
  SimpleGrid,
  Grid,
  Box,
  Heading,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react';
import ClockMobile from '../Assets/clockMobile.png';

const HomeSection2 = () => {
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }}  gap={3}
        p={10}
        h="80vh"
        color="white">
        <Flex
          borderRadius="5px"
          color="black"
          justifyContent="center"
          alignItems="center"
          p={2}
        
        >
          <Image src={ClockMobile} maxW={{ base: '200px', md: '400px' }} maxH="400px" />
        </Flex>

        <Flex
          borderRadius="5px"
          flexDirection="column"
          justifyContent="center"
        //   bg="#9990ce"
        color="black"
          p={5}
         
        >
          <Heading as="h2" size="lg" mb={4}>
            Discover Minutes: Your Path to Mindful Productivity
          </Heading>
          <Text fontSize="lg" fontFamily="Open Sans">
            Struggling with procrastination? Meet Minutes, the user-friendly time manager that believes in the power of small steps to achieve big goals. Sign up, choose a goal and start the timer. Let Minutes guide you toward unlocking your true potential—one focused minute at a time.
          </Text>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default HomeSection2;
