import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
const firebaseConfig = {
  apiKey: "AIzaSyDKUWPrV5Hq6h7E027qs_sXmXqCb6yIKk4",
  authDomain: "zzzz-6e752.firebaseapp.com",
  projectId: "zzzz-6e752",
  storageBucket: "zzzz-6e752.appspot.com",
  messagingSenderId: "570181226931",
  appId: "1:570181226931:web:4577fd87a9178201a5fbc6"
  };

initializeApp(firebaseConfig);

const messaging = getMessaging();


export const requestForToken = () => {
  return new Promise((resolve, reject) => {
    getToken(messaging, { vapidKey: 'BFh6BMR2B-Ti7XB3nIwADONC9oTFJI-1dqi-rJN4vTp5aiB4j5r-zeZwsWX1UVFwvk-0k2FJMEn4dWM7k_m9fD8' })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Current token for client:', currentToken);
          resolve(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
          reject(new Error('No registration token available'));
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token.', err);
        reject(err);
      });
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Payload', payload);
      resolve(payload);
    });
  });
};