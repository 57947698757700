import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  FormControl,
  FormLabel,
  Image,
  Icon,
  Input,
  Spinner,
  Container,
  IconButton,
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';

import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';
import { useGetLoggedInUser, useUpdateProfile } from '../utils/user.api';
import { useUploadMedia } from '../utils/media.api';
import { toast } from 'react-toastify';
import { CgProfile } from 'react-icons/cg';

const Profile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getLoginedUser = useGetLoggedInUser();
  const useUpdateProfileMutation = useUpdateProfile();
  const { isLoading } = useUpdateProfileMutation;

  const uploadMediaMutation = useUploadMedia();

  const [userDetails, setUserDetails] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    image: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  const handleUpload = async e => {
    const file = e.target.files[0];

    const formDataImg = new FormData();
    formDataImg.append('file', file);
    const img = await uploadMediaMutation.mutateAsync(formDataImg);

    setUserDetails({ ...userDetails, image: img });

    // setFormData({ ...formData, image: img });
  };

  const handleEdit = async () => {
    try {
      const userData = {
        fullName: userDetails?.fullName,
        userName: '',
        email: userDetails?.email,
        phoneNumber: userDetails?.phoneNumber,
        image: userDetails?.image,
      };

      await useUpdateProfileMutation.mutateAsync(userData);
      toast.success('Profile edited successfully');
      onClose();
    } catch (error) {
     
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (getLoginedUser.isError) {
      return;
    }

    if (getLoginedUser.data && getLoginedUser.data.user) {
      const { fullName, email, phoneNumber } = getLoginedUser?.data?.user;
      setUserDetails({
        fullName,
        email,
        phoneNumber,
      });
    }
  }, [getLoginedUser.data, getLoginedUser.isError]);

  return (
    <>
      <WebNav />
      <MobileNav />

      <Box ml="1.5rem">
        <Text fontWeight="bold" color="#B7b0dd" fontSize="30px" ml="30px">
          Profile
        </Text>
      </Box>

      <Container py={5}>
        <Flex justify="center" align="center" className="h-100">
          <Box mb={{ base: 4, lg: 0 }} borderRadius={10} width="400px">
            <Box mb={3} borderRadius=".5rem">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                align="center"
                // justify="center"

                bg="#b7b0dd"
                color="white"
                p={4}
                borderRadius=".5rem"
                height="150px"
              >
                <IconButton
                  my={{ base: 5, md: 0 }}
                  mr={{ base: 0, md: 4 }}
                  style={{ width: '100px' }}
                  aria-label="Profile image"
                  borderRadius="full"
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                  icon={
                    getLoginedUser?.data?.user?.image ? (
                      <Image
                        src={getLoginedUser?.data?.user?.image}
                        boxSize="100px"
                        borderRadius="full"
                        boxShadow="0 0 0 1px white"
                      />
                    ) : (
                      <CgProfile size="50px" color="#8c5ec9" />
                    )
                  }
                />

                <Box
                  textAlign={{ base: 'center', md: 'left' }}
                  mt={{ base: 3 }}
                >
                  <Text as="h5" fontWeight="bold">
                    {getLoginedUser?.data?.user?.fullName}
                  </Text>

                  <Icon
                    onClick={onOpen}
                    cursor="pointer"
                    as={FaEdit}
                    mb={{ base: 5, md: 0 }}
                  />
                </Box>
              </Flex>
            </Box>
            <Box bg="#b7b0dd" borderRadius=".5rem" p={4}>
              <Text as="h6" fontWeight="bold">
                Information
              </Text>
              <hr mt={0} mb={4} />
              <Flex>
                <Box size="6" mb={3}>
                  <Text as="h6" fontWeight="bold">
                    Email
                  </Text>
                  <Text color="white">{getLoginedUser?.data?.user?.email}</Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="3">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
              />
            </FormControl>
            {/* <FormControl mb="3">
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="text"
                name="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={handleInputChange}
              />
            </FormControl> */}
            <FormControl mb="3">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="fullName"
                value={userDetails.fullName}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mb="3">
              <FormLabel>Image</FormLabel>
              <Input
                type="file"
                name="image"
                // value={userDetails.image}
                onChange={e => {
                  handleUpload(e);
                }}
              />
            </FormControl>
            <Button backgroundColor="#b7b0dd" onClick={handleEdit}>
              {isLoading ? <Spinner size="xs" /> : 'Update Profile'}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Profile;
