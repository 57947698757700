import React from 'react';
import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import WebNav from '../Components/WebNav';
import MobileNav from '../Components/MobileNav';

const LeaderBoard = () => {
  const users = [
    { name: 'John Doe', score: 500 },
    { name: 'Jane Smith', score: 480 },
    { name: 'Alice Johnson', score: 460 },
    // Add more user data as needed
  ];

  return (
    <>
      <WebNav />
      <MobileNav />

      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt={10}
      >
        <Heading color="#B7b0dd" as="h1" mb={6}>
          Leaderboard
        </Heading>

        <Box width="70%" borderRadius="md" boxShadow="md" overflow="hidden">
          <Box bg="#B7b0dd" p={4} color="white">
            <Flex justify="space-between" alignItems="center">
              <Text fontWeight="bold">Rank</Text>
              <Text fontWeight="bold">User</Text>
              <Text fontWeight="bold">Score</Text>
            </Flex>
          </Box>

          {users.map((user, index) => (
            <Box
              key={user.name}
              p={4}
              borderBottom="1px solid"
              borderBottomColor="gray.300"
              bg={index % 2 === 0 ? 'white' : 'gray.50'}
            >
              <Flex justify="space-between" alignItems="center">
                <Text>
                  {index + 1}
                  {index === 0 && '🥇'}
                </Text>
                <Text>{user.name}</Text>
                <Text>{user.score}</Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </Flex>
    </>
  );
};

export default LeaderBoard;
