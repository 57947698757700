import React, { useState } from 'react';
import {
  Box,
  Text,
  Stack,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  Flex,
  Divider,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import {
  useGetAllGoals,
  useUpdateGoal,
  useDeletGoalById,
  useAddNoteToGoal,
  useDeletGoalNoteById,
  useGetAllOfCurrentUser,
} from '../utils/user.api';
import { FaEdit } from 'react-icons/fa';

import { toast } from 'react-toastify';
import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';

import Clock from '../Components/Clock';
import { MdDelete } from 'react-icons/md';

const Dairy = () => {
  const getAllGoals = useGetAllGoals();
  // const { data: goals, isLoading } = getAllGoals;
  const updateGoalMutation = useUpdateGoal();
  const { isLoading: updateGoalLoading } = updateGoalMutation;

  const deleteGoalByIdMutation = useDeletGoalById();
  const { isLoading: deletGoalisLoading } = deleteGoalByIdMutation;

  const addNoteToGoalMutation = useAddNoteToGoal();
  const { isLoading: addNoteToGoalisLoading } = addNoteToGoalMutation;

  const deletGoalNoteMutation = useDeletGoalNoteById();
  const { data: getAllGoalsOfCurrenrUserMutation, isLoading } =
    useGetAllOfCurrentUser();

  const [editingGoal, setEditingGoal] = useState('');
  const [goalidForNew] = useState('');
  const [editedGoalName, setEditedGoalName] = useState('');
  const [editedNotes, setEditedNotes] = useState([]);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [newNoteText, setNewNoteText] = useState('');

  const [isSmallerScreen] = useMediaQuery('(max-width: 48em)');

  const [IsDeleteNoteModel, setIsDeleteNoteModel] = useState(false);
  const [NoteDeletData, setNoteDeleteData] = useState({});

  const handleIsOpenDeleteNoteModel = (goalId, noteId) => {
    setIsDeleteNoteModel(true);
    setNoteDeleteData({
      goalId,
      noteId,
    });
  };

  const handleIsCloseDeleteNoteModel = () => {
    setNoteDeleteData({});
    setIsDeleteNoteModel(false);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [goalIdToDelete, setGoalIdToDelete] = useState(null);

  const handleDeleteC = goalId => {
    setGoalIdToDelete(goalId);
    onOpen();
  };

  const handleDeleteConfirmed = () => {
    handleDelete(goalIdToDelete);
    onClose();

    setGoalIdToDelete(null);
  };

  const handleDeleteCancel = () => {
    onClose();

    setGoalIdToDelete(null);
  };

  const handleCloseEditModal = () => {
    setEditingGoal(null);
    setEditedGoalName('');
    setEditedNotes([]);
  };

  const handleEdit = goal => {
    setEditingGoal(goal);
    setEditedGoalName(goal.goalName);
    setEditedNotes([...goal.goalNotes]);
  };

  const handleSaveEdit = async () => {
    try {
      const formattedGoalNotes = editedNotes.map(note => note.note);
      const updatedGoal = {
        goalId: editingGoal.id,
        goalName: editedGoalName,
        goalNotes: formattedGoalNotes,
      };

      await updateGoalMutation.mutateAsync(updatedGoal);

      toast.success('Goal updated successfully');
      setEditingGoal(null);
      setEditedGoalName('');
      setEditedNotes([]);
    } catch (error) {
      toast.error('Error updating goal:', error);
      console.error('Error updating goal:', error);
    }
  };

  const handleDelete = async goalId => {
    const formData = {
      goalId: goalId,
    };
    try {
      await deleteGoalByIdMutation.mutateAsync(formData);
      toast.success('Goal deleted successfully');
    } catch (error) {
      toast.error('Error deleting goal:', error);
      console.error('Error deleting goal:', error);
    }
  };

  const handleCloseAddNoteModal = () => {
    setAddNoteModalOpen(false);
  };

  const handleDeleteNote = async (goalid, NoteId) => {
    try {
      const formData = {
        goalId: NoteDeletData?.goalId,
        noteId: NoteDeletData?.noteId,
      };

      const response = await deletGoalNoteMutation.mutateAsync(formData);
      handleIsCloseDeleteNoteModel();
      toast.success(response.message);
    } catch (error) {
      toast.error(error?.message);
      console.error('Error deleting note:', error);
    }
  };

  const handleSaveNewNote = async () => {
    try {
      const formData = {
        goalId: goalidForNew,
        goalNotes: newNoteText,
      };

      await addNoteToGoalMutation.mutateAsync(formData);
      setNewNoteText('');

      toast.success('Note added successfully');
      setAddNoteModalOpen(false);
      getAllGoals.refetch();
    } catch (error) {
      toast.error('Error adding note:', error);
      console.error('Error adding note:', error);
    }
  };

  const renderGoals = () => {
    if (isLoading) {
      return (
        <p>
          {' '}
          <Spinner size="xl" />
        </p>
      );
    }

    if (
      !getAllGoalsOfCurrenrUserMutation ||
      getAllGoalsOfCurrenrUserMutation.length === 0
    ) {
      return <p>No goals found.</p>;
    }

    return getAllGoalsOfCurrenrUserMutation.map(goal => (
      <Box>
        <Box
          bg="#d2cee8"
          justifyContent="center"
          justifyItems="center"
          alignItems="center"
          key={goal.id}
          borderRadius="md"
          width={isSmallerScreen ? '100%' : '30em'}
          p={4}
          mb={4}
          boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
        >
          <Flex display="flex" alignItems="center">
            <Text mb="1px" fontWeight="bold" mr="10px">
              Goal Name:
            </Text>
            <Text color="#843ee0" fontWeight="bold">
              {goal.goalName}
            </Text>
          </Flex>

          <Text>
            <Text mb="1px" fontWeight="bold" mt="10px">
              Goal Notes:
            </Text>
            {goal.goalDays.length > 0 ? (
              goal.goalDays.map((note, index) => (
                <React.Fragment key={index}>
                  {note.note && ( // Check if note exists and is not empty
                    <>
                      <Flex alignContent="center" alignItems="center">
                        <Text fontWeight="bold">{note.date}</Text>
                        <IconButton
                          size="xs"
                          fontSize="lg"
                          _hover={{ bg: '#8f81e2' }}
                          variant="ghost"
                          backgroundColor="#b7b0dd"
                          color="red"
                          marginLeft="2"
                          onClick={() =>
                            handleIsOpenDeleteNoteModel(goal.id, note._id)
                          }
                          icon={<MdDelete />}
                        />
                      </Flex>
                      <Text color="#843ee0">{note.note}</Text>
                    </>
                  )}
                </React.Fragment>
              ))
            ) : (
              <Text color="#7c29e6">No goal notes available</Text>
            )}
          </Text>

          <Stack
            direction={isSmallerScreen ? 'column' : 'row'}
            spacing={4}
            mt={4}
            justifyContent="space-between"
          >
            <Box>
              <Button
                color="white"
                bg="#b7b0dd"
                mr="15px"
                onClick={() => handleEdit(goal)}
                _hover={{ bg: '#a49cd3' }}
                leftIcon={<FaEdit />}
              >
                Edit
              </Button>
            </Box>

            <Button
              colorScheme="red"
              onClick={() => handleDeleteC(goal.id)}
              leftIcon={<MdDelete />}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Box>
    ));
  };

  return (
    <Box position="relative" pb={{ base: 12, md: 0 }}>
      <MobileNav />
      <WebNav />

      <Flex
        direction="column"
        alignItems="center"
        mt={{ base: '45px', md: '0px' }}
      >
        <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
          Diary
        </Text>
        <Divider
          mb={3}
          mt={-2}
          borderWidth="2px"
          borderColor="#9990ce"
          w={{ base: '25%', md: '10%' }}
        />
      </Flex>
      <Stack justifyContent="center" alignItems="center" spacing={4} mt={4}>
        {renderGoals()}
      </Stack>

      {/* Edit Goal Modal */}
      <Modal isOpen={!!editingGoal} onClose={handleCloseEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Goal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={editedGoalName}
              onChange={e => setEditedGoalName(e.target.value)}
              placeholder="Enter edited goal name"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="#FFFFFF"
              bg="#7c29e6"
              mr={3}
              isDisabled={updateGoalLoading}
              onClick={handleSaveEdit}
            >
              {updateGoalLoading ? <Spinner size="sm" /> : 'Save'}
            </Button>
            <Button onClick={handleCloseEditModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Note Modal */}
      <Modal isOpen={addNoteModalOpen} onClose={handleCloseAddNoteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Note</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Note:</FormLabel>
              <Input
                type="text"
                value={newNoteText}
                onChange={e => setNewNoteText(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              isDisabled={addNoteToGoalisLoading}
              mr={3}
              onClick={handleSaveNewNote}
            >
              {addNoteToGoalisLoading ? <Spinner size="sm" /> : ' Save Note'}
            </Button>
            <Button onClick={handleCloseAddNoteModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation modal */}
      <Modal isOpen={isOpen} onClose={handleDeleteCancel} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this goal?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              isDisabled={deletGoalisLoading}
              onClick={handleDeleteConfirmed}
            >
              {deletGoalisLoading ? <Spinner size="sm" /> : 'Yes'}
            </Button>
            <Button onClick={handleDeleteCancel}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Model for Delete Note  */}
      <Modal
        isOpen={IsDeleteNoteModel}
        onClose={handleIsCloseDeleteNoteModel}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this note?</ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteNote}>
              Yes
            </Button>
            <Button variant="ghost" onClick={handleIsCloseDeleteNoteModel}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Clock />
    </Box>
  );
};

export default Dairy;
