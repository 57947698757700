import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Button,
  Text,
  Input,
  Select,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  Textarea,
  Spinner,
} from '@chakra-ui/react';
import Confetti from 'react-confetti';
// import useWindowSize from 'react-use/lib/useWindowSize'
import GoalSound from '../Assets/goalCompleted.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { useTimer } from './TimerContext';
import { useStopWatchSet, useStopWatchGet } from '../utils/auth.api';
import {
  useUpdateGoalTime,
  useGetGoalTime,
  useGetAllOfCurrentUser,
} from '../utils/user.api';
import Countdown from 'react-countdown';
import { render } from '@testing-library/react';

const StopWatch = ({ isOpen, onClose, goalId, goalName }) => {
  const countDownRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [countdownTime, setCountdownTime] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });
  const [note, setNote] = useState('');
  const [showGoalNotesInput, setShowGoalNotesInput] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [timeReachedZero, setTimeReachedZero] = useState(false);
  const [audio] = useState(new Audio(GoalSound));

  const [initialHours, setInitialHours] = useState('00');
  const [initialMinutes, setInitialMinutes] = useState('00');
  const [initialSeconds, setInitialSeconds] = useState('00');
  // const [remainingTime, setRemainingTime] = useState(
  //   localStorage.getItem('remainingTime')
  //     ? JSON.parse(localStorage.getItem('remainingTime') || '0')
  //     : 0
  // );

  const [showData, setShowData] = useState(false);
  // const [elapsedTime, setElapsedTime] = useState(0);
  // const [elapsedTime, setElapsedTime] = useState(() => {
  //   const storedRemainingTime = localStorage.getItem('elapsedTime');
  //   return storedRemainingTime ? JSON.parse(storedRemainingTime) : 0;
  // });

  // set the countdown time
  const [remainingTime, setRemainingTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isStopped, setIsStopped] = useState(true);
  const intervalRef = useRef(null);
  const hasHandledActions = useRef(false);
  const [getidFromSelect, setGetSelectId] = useState('');

  const { isRunning, setIsRunning } = useTimer();

  // set the goal countdown time
  const stopwatchsetMutation = useStopWatchSet();

  //get the goal countdown time
  const getStopwatchTimeMutation = useStopWatchGet(
    localStorage.getItem('selectedGoalId')
  );
  const { data: getStopwatchTime, isLoading: stopwatchLoading } =
    getStopwatchTimeMutation;

  const upadteGoalTimeMutation = useUpdateGoalTime();
  const { isLoading } = upadteGoalTimeMutation;

  const getGoalTimeMutation = useGetGoalTime();

  const getAllOfCurrentUserMutation = useGetAllOfCurrentUser();
  const { data: getAllGoalsOfCurrenrUser } = getAllOfCurrentUserMutation;

  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (completed) {
      // Render a completed state
      return <>00:00:00</>;
    } else if (
      !getStopwatchTime ||
      Object.keys(getStopwatchTime).length === 0
    ) {
      //   const data = { elapseTime:0, remainingTime: 0 };
      // stopWatchSetBackend(data);
      // setRemainingTime(0);

      // setElapsedTime(0);

      return <span>00:00:00</span>;
    } else {
      // Render a countdown
      return (
        <>
          {stopwatchLoading ? (
            <Spinner />
          ) : (
            <span>
              {hours < 10 ? '0' + hours : hours}:
              {minutes < 10 ? '0' + minutes : minutes}:
              {seconds < 10 ? '0' + seconds : seconds}
            </span>
          )}
        </>
      );
    }
  };

  const presentDate = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formattedDate = presentDate();

  const storedGoalIdd = localStorage.getItem('selectedGoalId');
  // function get the goal countdown time when changed
  useEffect(() => {
    if (getidFromSelect) {
      getStopwatchTimeMutation.refetch();
    }
  }, [getidFromSelect, isOpen, onClose]);

  useEffect(() => {
    if (
      getidFromSelect &&
      getStopwatchTime &&
      Object.keys(getStopwatchTime).length !== 0
    ) {
      setElapsedTime(getStopwatchTime?.elapseTime);
      setRemainingTime(getStopwatchTime?.remainingTime);
      setTotalTime(getStopwatchTime?.totalTime);
      setIsStopped(getStopwatchTime?.isStopped);
    } else {
      setElapsedTime(0);
      setRemainingTime(0);
      setTotalTime(0);
      setIsStopped(true);
    }
  }, [getidFromSelect, getStopwatchTime, isOpen, onClose]);

  const stopWatchSetBackend = async data => {
    const elapsedTimeToUpdate = data?.elapseTime ? data?.elapseTime : 0;
    const remainingTimeToUpdate = data?.remainingTime ? data?.remainingTime : 0;

    const formData = {
      goalId: localStorage.getItem('selectedGoalId')
        ? localStorage.getItem('selectedGoalId')
        : '1212423',
      elapseTime: elapsedTimeToUpdate,
      remainingTime: remainingTimeToUpdate,
      totalTime: data?.totalTime,
      IsDelete: data?.IsDelete,
      isStopped: data?.isStopped,
    };

    try {
      const mutationResult = await stopwatchsetMutation.mutateAsync(formData);
      getStopwatchTimeMutation.refetch();

      // toast.success('sdafsd')
    } catch (error) {
      // toast.error('Failed to set stopwatch time', error);
      console.log(error);
    }
  };

  const handleGetGoalTime = async e => {
    const presentDate = new Date().toLocaleDateString('en-US');

    const formData = {
      goalId: getidFromSelect ? getidFromSelect : goalId,
      date: presentDate,
    };

    try {
      const response = await getGoalTimeMutation.mutateAsync(formData);
    } catch (error) {
      // toast.error('Login Failed', error);
    }
  };

  const startStopwatch = () => {
    countDownRef?.current?.start();
    setIsRunning(true);
    setIsStopped(false);
    // If there's no initial time set, fetch the goal time from the backend
    if (elapsedTime === 0) {
      handleGetGoalTime();
    }
  };

  const handleSetInitialTime = () => {
    // Convert initial time to seconds
    const totalInitialTime =
      parseInt(initialHours, 10) * 3600 +
      parseInt(initialMinutes, 10) * 60 +
      parseInt(initialSeconds, 10);
    setRemainingTime(totalInitialTime);
    // localStorage.setItem('remainingTime', JSON.stringify(totalInitialTime));
    const data = {
      remainingTime: totalInitialTime,
      totalTime: totalInitialTime,
      isStopped: true,
    };
    stopWatchSetBackend(data);
    // getStopwatchTimeMutation.refetch();
    // setElapsedTime(0);
    // setInitialHours('00');
    // setInitialMinutes('00');
    // setInitialSeconds('00');
  };

  // if(logout){
  //   countDownRef?.current?.pause();
  // }
  const pauseStopwatch = () => {
    countDownRef?.current?.pause();
    setIsStopped(true);
    setIsRunning(false);
    setShowButton(true);
    clearInterval(intervalRef.current);
  };

  const resetStopwatch = () => {
    // countDownRef?.current?.pause();

    const data = { IsDelete: true, isStopped: true };

    stopWatchSetBackend(data);
    setIsStopped(true);
    // setElapsedTime(0);
    // pauseStopwatch();

    // setShowButton(false);
    setNote('');
    setShowButton(false);
    setShowData(false);
    setInitialHours('00');
    setInitialMinutes('00');
    setInitialSeconds('00');
    // hasHandledActions.current = false;
  };

  const handleGoalTime = async () => {
    const formData = {
      goalId: getidFromSelect ? getidFromSelect : '',
      date: formattedDate,
      timeSpent: elapsedTime,
      note: note,
    };

    try {
      await upadteGoalTimeMutation.mutateAsync(formData);
      // onclose();
      onClose();
      const data = { IsDelete: true };

      stopWatchSetBackend(data);
      // setElapsedTime(0);
      // pauseStopwatch();

      // setShowButton(false);
      setNote('');
      setShowButton(false);
      setShowData(false);
      setInitialHours('00');
      setInitialMinutes('00');
      setInitialSeconds('00');
      toast.success('Goal time updated!');
    } catch (error) {
      toast.error('Failed to add goal time', error);
    }
  };

  const handleSelectChange = async e => {
    const selectedGoalId = e.target.value;

    if (!isRunning) {
      // setElapsedTime(0);
      // setRemainingTime(0);
      // localStorage.removeItem('elapsedTime');
      // localStorage.removeItem('remainingTime');

      localStorage.setItem('selectedGoalId', selectedGoalId);

      setGetSelectId(selectedGoalId); // Save the selected goal ID to local storage only when the stopwatch isn't running
    } else {
      // setGetSelectId(getidFromSelect)
      const storedRemainingTime = localStorage.getItem('selectedGoalId');
      setGetSelectId(storedRemainingTime);
    }
  };

  useEffect(() => {
    if (isOpen || getidFromSelect) {
      try {
        handleGetGoalTime();
      } catch (error) {
        console.error('Error in handleGetGoalTime:', error);
      }
    }
  }, [isOpen, getidFromSelect]);

  useEffect(() => {
    if (goalId) {
      if (!isRunning) {
        setGetSelectId(goalId); // Save the selected goal ID to local storage only when the stopwatch isn't running
      } else {
        const storedRemainingTime = localStorage.getItem('selectedGoalId');
        setGetSelectId(storedRemainingTime);
      }
    } else if (getAllGoalsOfCurrenrUser?.length > 0) {
      // If goalId is not provided, set getidFromSelect to the first goal in getAllGoalsOfCurrenrUser

      if (!isRunning) {
        setGetSelectId(getAllGoalsOfCurrenrUser[0]?.id);
      } else {
        const storedRemainingTime = localStorage.getItem('selectedGoalId');
        setGetSelectId(storedRemainingTime);
      }
    }
  }, [goalId, getAllGoalsOfCurrenrUser]);

  // handle goal notes
  const handleToggleGoalNotes = () => {
    setShowGoalNotesInput(!showGoalNotesInput);
  };

  //handle goal note changes
  const handleGoalNotesChange = e => {
    setNote(e.target.value);
  };

  // useEffect(() => {
  //   localStorage.setItem('elapsedTime', JSON.stringify(elapsedTime));
  // }, [elapsedTime]);

  // useEffect(() => {
  //   localStorage.setItem('remainingTime', JSON.stringify(remainingTime));
  // }, [remainingTime]);
  // useEffect(() => {
  //   if (!isStopped) {
  //     countDownRef.current?.start();
  //   }
  // }, [isStopped]);

  useEffect(() => {
    const storedGoalId = localStorage.getItem('selectedGoalId');
    if (!storedGoalId && getAllGoalsOfCurrenrUser?.length > 0) {
      // Save the first goalId to localStorage if it's not already present
      localStorage.setItem('selectedGoalId', getAllGoalsOfCurrenrUser[0]?.id);
    }
  }, [getAllGoalsOfCurrenrUser]);

  const storedGoalId = localStorage.getItem('selectedGoalId');
  useEffect(() => {
    setGetSelectId(storedGoalId);
  }, [storedGoalId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {showData ? <Confetti width="450%" /> : null}
        <ModalHeader>
          {getAllGoalsOfCurrenrUser?.length > 0 ? (
            isRunning ? (
              // When stopwatch is running, display the name of the goal from localStorage
              <>
                {getidFromSelect
                  ? // Find the selected goal name based on the stored ID
                    getAllGoalsOfCurrenrUser
                      .filter(goal => goal.id === getidFromSelect)
                      .map(goal => (
                        <span key={goal.id}>{goal.goalName}:Goal Started</span>
                      ))
                  : 'Start Goal Time for'}
              </>
            ) : (
              // When stopwatch is not running, display the Select dropdown for goal selection
              <>
                Start Goal Time for
                {getAllGoalsOfCurrenrUser && (
                  <Select
                    // defaultValue={localStorage.getItem('selectedGoalId')}
                    size="md"
                    mt={4}
                    onChange={handleSelectChange}
                  >
                    {getAllGoalsOfCurrenrUser.map(goal => (
                      <option
                        key={goal.id}
                        value={goal.id}
                        disabled={
                          goal.id === localStorage.getItem('selectedGoalId') &&
                          isRunning
                        }
                        selected={
                          goal.id === localStorage.getItem('selectedGoalId')
                        }
                      >
                        {goal.goalName}
                      </option>
                    ))}
                  </Select>
                )}
              </>
            )
          ) : (
            'No Goals to Start Stopwatch'
          )}
        </ModalHeader>

        <ModalBody>
          {getAllGoalsOfCurrenrUser?.length > 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={'240px'} value={'100'} thickness={'4px'}>
                <CircularProgressLabel>
                  {isEditing ? (
                    // Editing view
                    <Flex
                      flexDirection="column"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="80%"
                        px={3}
                      >
                        <Text fontSize="15px" mx="2">
                          H
                        </Text>
                        <Input
                          type="number"
                          name="initialHours"
                          value={initialHours}
                          px={0}
                          onChange={e => setInitialHours(e.target.value)}
                          style={{ width: '50px', textAlign: 'center' }}
                        />

                        <Text fontSize="15px" mx="2">
                          M
                        </Text>
                        <Input
                          type="number"
                          name="initialMinutes"
                          px={0}
                          value={initialMinutes}
                          onChange={e => setInitialMinutes(e.target.value)}
                          style={{ width: '50px', textAlign: 'center' }}
                        />
                        <Text fontSize="15px" mx="2">
                          S
                        </Text>

                        <Input
                          type="number"
                          px={0}
                          name="initialSeconds"
                          value={initialSeconds}
                          onChange={e => setInitialSeconds(e.target.value)}
                          style={{ width: '50px', textAlign: 'center' }}
                        />
                      </Flex>

                      <Button
                        size="xs"
                        mt="2"
                        bg="#b7b0dd"
                        onClick={() => {
                          handleSetInitialTime();
                          setIsEditing(false);
                        }}
                      >
                        Set Time
                      </Button>
                    </Flex>
                  ) : (
                    // Display view
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="space-around"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        size="xs"
                        mt="1em"
                        bg="#b7b0dd"
                        onClick={() => {
                          setIsEditing(true);
                          setShowData(false);
                        }}
                      >
                        set Time Frame
                      </Button>

                      <Text
                        pt="1"
                        borderBottom="2px solid #7c29e6"
                        width="70%"
                        display="flex"
                        justifyContent="center"
                        fontSize="36px"
                        fontWeight="bold"
                      >
                        {getAllGoalsOfCurrenrUser?.length > 0 &&
                          (remainingTime ? (
                            <>
                              <Countdown
                                date={Date.now() + remainingTime * 1000}
                                key={1}
                                autoStart={!isStopped}
                                zeroPadTime={1}
                                precision={0}
                                controlled={false}
                                renderer={remainingTime ? renderer : null}
                                ref={countDownRef}
                                onComplete={e => {
                                  const data = {
                                    goalId: getidFromSelect,
                                    elapseTime: totalTime,
                                    remainingTime: 0,
                                    isStopped: true,
                                    IsDelete: false,
                                    totalTime: totalTime,
                                  };
                                  stopWatchSetBackend(data);
                                  console.log('on completed called .....', e);
                                  audio
                                    .play()
                                    .catch(error =>
                                      console.error('Audio error:', error)
                                    );

                                  setRemainingTime(0);
                                  // setElapsedTime(0);
                                  pauseStopwatch();

                                  setShowData(true);
                                }}
                                onStart={e => {
                                  const data = {
                                    goalId: getidFromSelect,

                                    elapseTime: elapsedTime,
                                    remainingTime: remainingTime,

                                    isStopped: false,
                                  };
                                  stopWatchSetBackend(data);
                                }}
                                onPause={e => {
                                  // setRemainingTime(prevTime => prevTime - 1);
                                  const data = {
                                    goalId: getidFromSelect,
                                    elapseTime: totalTime - e?.total / 1000,
                                    remainingTime: e?.total / 1000,
                                    isStopped: true,
                                    IsDelete: false,
                                    totalTime: totalTime,
                                  };

                                  stopWatchSetBackend(data);
                                }}
                                onTick={e => {
                                  console.log(
                                    'on tick reamaintime',
                                    remainingTime,
                                    'elapsed time',
                                    elapsedTime,
                                    e
                                  );
                                  // setElapsedTime(prevTime => prevTime + 1);
                                  // setRemainingTime(prevTime => prevTime - 1);
                                }}
                              />
                            </>
                          ) : (
                            '00:00:00'
                          ))}
                        {/* {`${hours}:${minutes}:${seconds}`} */}
                      </Text>
                      <Box display="flex" p="0" m="0" pt="10px">
                        {isStopped ? (
                          <div
                            onClick={() => {
                              if (remainingTime > 0) {
                                startStopwatch();
                              }
                            }}
                            style={{
                              fontSize: '25px',
                              paddingRight: '15px',
                              cursor: 'pointer',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPlay}
                              style={{ color: '#7c29e6', size: '30px' }}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              pauseStopwatch();
                            }}
                            style={{
                              fontSize: '25px',
                              paddingRight: '15px',
                              cursor: 'pointer',
                            }}
                          >
                            {' '}
                            <FontAwesomeIcon
                              icon={faPause}
                              style={{ color: '#7c29e6', size: '35px' }}
                            />
                          </div>
                        )}

                        <div
                          onClick={() => {
                            resetStopwatch();
                          }}
                          style={{ fontSize: '24px', cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon
                            icon={faRepeat}
                            style={{ color: '#7c29e6' }}
                          />
                        </div>
                      </Box>
                    </Box>
                  )}
                </CircularProgressLabel>
              </CircularProgress>

              {showData ? (
                <Flex flexDirection="column">
                  {showGoalNotesInput && (
                    <FormControl mb={4}>
                      <FormLabel>Note:</FormLabel>
                      <Textarea
                        type="text"
                        name="goalNotes"
                        value={note}
                        placeholder="Enter your goal notes..."
                        onChange={handleGoalNotesChange}
                      />
                    </FormControl>
                  )}

                  {!showGoalNotesInput && (
                    <Button
                      mt={4}
                      bgColor="#B7b0dd"
                      color="black"
                      borderRadius="full"
                      onClick={handleToggleGoalNotes}
                      _hover={{ bg: 'gray.300' }}
                    >
                      Save with Notes
                    </Button>
                  )}

                  {showGoalNotesInput && (
                    <>
                      <Button
                        mt={4}
                        bgColor="#B7b0dd"
                        color="black"
                        borderRadius="full"
                        onClick={handleGoalTime}
                        _hover={{ bg: 'gray.300' }}
                        isDisabled={isLoading}
                      >
                        {isLoading ? <Spinner size="xs" /> : 'Save With Note'}
                      </Button>

                      <Button
                        mt={4}
                        bgColor="#B7b0dd"
                        color="black"
                        borderRadius="full"
                        onClick={handleToggleGoalNotes}
                        _hover={{ bg: 'gray.300' }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}

                  {!showGoalNotesInput && (
                    <Button
                      mt={4}
                      bgColor="#B7b0dd"
                      color="black"
                      borderRadius="full"
                      onClick={handleGoalTime}
                      _hover={{ bg: 'gray.300' }}
                      isDisabled={isLoading}
                    >
                      {isLoading ? <Spinner size="xs" /> : 'Save without notes'}
                    </Button>
                  )}
                </Flex>
              ) : null}
            </Box>
          ) : (
            <Text textAlign="center">
              You don't have any goals to start a stopwatch.
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StopWatch;
