import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAddGoal } from '../utils/user.api';

const GoalModal = ({ isOpen, onClose }) => {
  const [selectedHours, setSelectedHours] = useState('00');
  const [selectedMinutes, setSelectedMinutes] = useState('00');
  const navigate = useNavigate();

  const addGoalMutation = useAddGoal();
  const { isLoading } = addGoalMutation;

  const [addGoalObject, setAddGoalObject] = useState({
    goalName: '',
    goalTime: {
      hours: selectedHours,
      minutes: selectedMinutes,
      seconds: 0,
    },
    goalNotes: '',
  });

  const [showGoalNotesInput, setShowGoalNotesInput] = useState(false);

  const handleGoalNameChange = event => {
    setAddGoalObject({ ...addGoalObject, goalName: event.target.value });
  };

  const handleGoalNotesChange = e => {
    setAddGoalObject({ ...addGoalObject, goalNotes: e.target.value });
  };

  const handleToggleGoalNotes = () => {
    setShowGoalNotesInput(!showGoalNotesInput);
  };

  const handleAddGoalWithoutNote = async () => {
    if (!addGoalObject?.goalName) {
      toast.error('Please Enter Goal Record');
      return;
    }

    const formData = {
      goalName: addGoalObject?.goalName,
      goalTime: {
        hours: addGoalObject?.goalTime?.hours,
        minutes: addGoalObject?.goalTime?.minutes,
        seconds: 0,
      },
      goalNotes: addGoalObject?.goalNotes,
    };

    try {
      const response = await addGoalMutation.mutateAsync(formData);
      // localStorage.setItem('selectedGoalId', response?.id);
      toast.success('Goal Added Successfully');
      navigate('/MyGoals');
      onClose();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Goal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Goal Name:</FormLabel>
            <Input
              type="text"
              name="goalName"
              placeholder="Enter your goal name..."
              onChange={handleGoalNameChange}
            />
          </FormControl>

          {showGoalNotesInput && (
            <FormControl mb={4}>
              <FormLabel>Goal Notes:</FormLabel>
              <Textarea
                type="text"
                name="goalNotes"
                placeholder="Enter your goal notes..."
                onChange={handleGoalNotesChange}
              />
            </FormControl>
          )}

          {/* {!showGoalNotesInput && (
            <Button
              mt={4}
              bgColor="#B7b0dd"
              color="black"
              borderRadius="full"
              onClick={handleToggleGoalNotes}
              _hover={{ bg: 'gray.300' }}
            >
              Save with Notes
            </Button>
          )} */}

          {showGoalNotesInput && (
            <>
              {/* <Button
                mt={4}
                bgColor="#B7b0dd"
                color="black"
                borderRadius="full"
                onClick={handleAddGoalWithoutNote}
                _hover={{ bg: 'gray.300' }}
                isDisabled={isLoading}
              >
                {isLoading ? <Spinner size="xs" /> : 'Save With Note'}
              </Button> */}

              <Button
                mt={4}
                ml={2}
                bgColor="#B7b0dd"
                color="black"
                borderRadius="full"
                onClick={handleToggleGoalNotes}
                _hover={{ bg: 'gray.300' }}
              >
                Cancel
              </Button>
            </>
          )}

          {!showGoalNotesInput && (
            <Button
              mt={4}
              ml={2}
              bgColor="#B7b0dd"
              color="black"
              borderRadius="full"
              onClick={handleAddGoalWithoutNote}
              _hover={{ bg: 'gray.300' }}
              isDisabled={isLoading}
            >
              {isLoading ? <Spinner size="xs" /> : 'Save Goal'}
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GoalModal;
