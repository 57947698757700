import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FcApproval } from 'react-icons/fc';
import moment from 'moment';
import { Box , Flex, Text} from '@chakra-ui/react';

const localizer = momentLocalizer(moment);

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedTime = [];
  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }
  if (minutes > 0 || hours > 0) {
    formattedTime.push(`${minutes}m`);
  }
  formattedTime.push(`${remainingSeconds}s`);

  return formattedTime.join(' ');
};

const CustomCalendar = ({ goalData }) => {
  const [formattedGoalDays, setFormattedGoalDays] = useState([]);

  useEffect(() => {
    if (goalData?.goalDays) {
      const groupedGoalDays = {};
      goalData.goalDays.forEach((goalDay) => {
        const date = moment(goalDay.date, 'D MMM YYYY').format('YYYY-MM-DD');
        if (!groupedGoalDays[date]) {
          groupedGoalDays[date] = 0;
        }
        groupedGoalDays[date] += goalDay.timeSpent;
      });

      const formattedDates = Object.entries(groupedGoalDays).map(([date, timeSpent]) => ({
        date: moment(date).toDate(),
        timeSpent,
      }));
      setFormattedGoalDays(formattedDates);
    }
  }, [goalData]);

  const events = formattedGoalDays.map((goalDay) => ({
    title: (
      <Box>
        <Flex justify="space-between" alignItems="center">
        <Text>{formatTime(goalDay.timeSpent)}</Text>

        <FcApproval size="35px" />
        </Flex>

       
        {/* <div>{`Time Spent: ${formatTime(goalDay.timeSpent)}`}</div> */}
      </Box>
    ),
    start: goalDay.date,
    end: goalDay.date,
  }));

  return (
    <Box
      h="500px"
      w="100%"
      maxW="700px"
      border="2px solid #B7B0DD"
      mt="50px"
      p="20px"
      borderRadius={'15px'}
      style={{ borderColor: '#B7B0DD' }}
    >
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ maxWidth: '800px', margin: 'auto' }}
        tooltipAccessor={() => null}
        components={{
          toolbar: (toolbar) => {
            const { onNavigate, view, label } = toolbar;
            return (
              <div className="rbc-toolbar">
                <button type="button" onClick={() => onNavigate('PREV')}>
                  Back
                </button>
                <span className="rbc-toolbar-label" style={{ fontWeight: 'bold' }}>
                  {`${label}`}
                </span>
                <button type="button" onClick={() => onNavigate('NEXT')}>
                  Next
                </button>
              </div>
            );
          },
        }}
      />
    </Box>
  );
};

export default CustomCalendar;
