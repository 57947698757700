// LandingPage.js
import React,{useState} from 'react';
import { Box, ChakraProvider, Container } from '@chakra-ui/react';

import Header from '../Components/Header';
import Hero from '../Components/HeroSection';
import HomeSection2 from '../Components/HomeSection2';
import HomeSection3 from '../Components/HomeSection3';
import HomeSection4 from '../Components/HomeSection4';
import HomeSection5 from '../Components/HomeSection5';
import Footer from '../Components/Footer';
// import { getMessaging, getToken } from 'firebase/messaging';
import { getMessaging, getToken } from 'firebase/messaging';


const LandingPage = () => {
 

  



  return (
    <ChakraProvider>
      <Box minH="100vh">
        <Header />

        <Hero />
        <Container maxW={{ md: '80%' }}>
          <HomeSection2 />
          <HomeSection3 />
          <HomeSection4 />
          <HomeSection5 />
        </Container>
        <Footer />
      </Box>
    </ChakraProvider>
  );
};

export default LandingPage;
