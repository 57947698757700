import React from 'react';
import {
  SimpleGrid,
  Grid,
  Box,
  Heading,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react';
// import ClockMobile from '../Assets/clockMobile.png';
import WhyChoseUs from '../Assets/questioning.png';

const HomeSection4 = () => {
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} h="80vh" color="white">
        <Flex
          flexDirection="column"
          justifyContent="center"
          color="black"
          p={5}
        >
          <Heading as="h2" size="2xl" mb={4}>
            Why Minutes ?
          </Heading>
          <Text size="lg" fontSize="lg" fontFamily="Open Sans">
            Minutes is not just a timer; it's a mindset shift. Embrace a fresh
            perspective on productivity that prioritizes progress over pressure.
            Take the time that feels right, one gentle minute at a time
          </Text>
        </Flex>

        <Flex
          //   bg="#9990ce"
          color="black"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src={WhyChoseUs}
            maxW={{ base: '200px', md: '400px' }}
            maxH="400px"
          />
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default HomeSection4;
