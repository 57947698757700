import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import Logo from '../Assets/heroSectionClock.png';

function PWAAlert(props) {
  const instructions = () => {
    if (props?.operatingSystem === 'iOS') {
      return (
        <Text mt="10px" color="#000" fontSize="14px" fontWeight="500">
          Tap on share and choose <strong>Add To Home</strong> to install the
          app <strong>if you are in Safari.</strong>
          <br /> <br /> <strong>NOTE:</strong> You won't be able to get
          notifications on IOS in browser
        </Text>
      );
    } else {
      return (
        <Text mt="10px" color="#000" fontSize="14px" fontWeight="500">
          Tap the More settings button on your <strong>Browser</strong> and
          choose <strong>Install App</strong> in the options
        </Text>
      );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <ModalBody p="12px 24px">
          <Flex align="center" gap="20px">
            <Image src={Logo} alt="logo" height="78px" width="78px" />
            <Flex flexDir="column">
              <Text
                textTransform="uppercase"
                mb="0"
                fontWeight="700"
                fontSize="16px"
                lineHeight="-0.18px"
                letterSpacing="-1px"
                fontFamily="Phonk"
              >
                Add To
              </Text>
              <Text
                textTransform="uppercase"
                fontWeight="700"
                fontSize="16px"
                lineHeight="-0.18px"
                letterSpacing="-1px"
                fontFamily="Phonk"
              >
                Home Screen
              </Text>
            </Flex>
          </Flex>

          <Text mt="20px" color="#000" fontSize="14px" fontWeight="500">
            Did you know? You can add this website to your home screen by just
            following below instructions
          </Text>
          {instructions()}
          <Flex justify="center" mt="20px">
            <Button
              onClick={() => {
                localStorage.setItem('isAlerted', true);
                props.onClose();
              }}
            >
              OK, Got it
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PWAAlert;
