import React from 'react';
import { Flex, Heading, Text, Image } from '@chakra-ui/react';

const ExperienceCard = ({ imageSrc, title, description,customBoxShadow }) => {

  
  return (
    <Flex
    
      p={{base:5, md:5}}
      borderRadius="5px"
      flexDirection="column"
      // bg="#9990ce"
      
      color="black"
      // color="black"
      // h="100%"
      // alignItems="center"
    >
      <Image
        src={imageSrc}
        maxW="90px"
        maxH="90px"
        ml={3}
       
      />

      <Heading
        as="h1"
        size="lg"
        // fontFamily="Open Sans"
        pt={3}
      
        // textAlign="center"
      >
        {title}
      </Heading>

      <Text pt={3} fontFamily="Open Sans">
        {description}
      </Text>
    </Flex>
  );
};

export default ExperienceCard;
