import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { CgStack } from 'react-icons/cg';
import { Sling as Hamburger } from 'hamburger-react';
import { useNavigate } from 'react-router-dom';
import Logo from "../Assets/logo1.png";



const Header = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      {/* Desktop view  */}
      <Flex
        bg="#b7b0dd"
        p={4}
        color="white"
        justify="space-between"
        alignItems="center"
        display={{ base: 'none', md: 'flex' }}
        position="fixed"
        width="100%"
        // pr="10px"
      >
        <Flex
          justify="center"
          align="center"
          cursor="pointer"
          onClick={() => {
            navigate('/');
          }}
        >
          <Image  src={Logo} w={9} h={9} mr={2}/>
          <Heading as="h1" size="lg" mr="3rem">
            Minutes
          </Heading>
        </Flex>

        <Flex>
          <Link
            onClick={() => {
              navigate('/auth', { state: 1 });
            }}
            mr={4}
            fontWeight="bold"
            _hover={{ textDecoration: 'none' }}
          >
            Login
          </Link>
          <Link
            onClick={() => {  
              navigate('/auth', { state: 2 });
            }}
            fontWeight="bold"
            _hover={{ textDecoration: 'none' }}
          >
            Sign up
          </Link>
        </Flex>
      </Flex>

      {/* Mobile view  */}
      <Flex
        bg="#b7b0dd"
        p={4}
        color="white"
        justify="space-between"
        alignItems="center"
        display={{ base: 'flex', md: 'none' }}
        position="fixed"
        width="100%"
      >
        <Flex justify="center" align="center"  cursor="pointer"
          onClick={() => {
            navigate('/');
          }}>
        <Image  src={Logo} w={9} h={9} mr={2}/>
          <Heading as="h1" size="lg" mr="3rem">
            Minutes
          </Heading>
        </Flex>

        <Flex>
          <Menu>
            <MenuButton onClick={onToggle}>
              <Hamburger toggled={isOpen} toggle={onToggle} />
            </MenuButton>
            <MenuList zIndex={3} isOpen={isOpen} onClose={onClose}>
              <MenuItem color="black" fontWeight="bold">
                Home
              </MenuItem>
              <MenuItem
                color="black"
                onClick={() => {
                  navigate('/auth', { state: 1 });
                }}
              >
                Login
              </MenuItem>
              <MenuItem
                color="black"
                onClick={() => {  
                  navigate('/auth', { state: 2 });
                }}
              >
                signup
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
