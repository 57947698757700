import React from 'react';
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Image,
  Center,
  Divider,
} from '@chakra-ui/react';
import FeatureCard from './FeatureCard';
import Easy from '../Assets/easy.png';
import Triger from '../Assets/stress-test.png';
import Goal from '../Assets/goal.png';
import statistic from '../Assets/rating.png';
import Diary from '../Assets/planner.png';
import Run from '../Assets/run.png';

const HomeSection3 = () => {
  return (
    <Box  borderRadius="7px" mt={{base:"250px", md:9}}  p={{base:7, md:1}}  justifyItems="center" alignItems="center">
    <Flex direction="column" alignItems="center">
      <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
      What Sets Minutes Apart?
      </Text>
      <Divider
        mb={3}
        mt={-2}
       
        borderWidth="2px"
        borderColor="#9990ce"
        w="49%"
      />
    </Flex>

    <SimpleGrid mt={8} columns={{ base: 1, md: 3 }} gap={2} color="white">
    <FeatureCard
          imageSrc={Easy}
          title="Easy Start"
          description="With Minutes, starting a task is a breeze. Break down large goals into small, manageable sessions, each lasting just a few minutes. It's the perfect solution for tasks that might seem overwhelming at first glance."
        />
        <FeatureCard
          imageSrc={Triger}
          title="Momentum Trigger"
          description="Once you start, you'll most likely find the motivation to keep going. What particular task or goal are you aiming to build momentum on?"
        />
        <FeatureCard
          imageSrc={Goal}
          title="Gentle Success"
          description="Say goodbye to pressure. Cultivate a habit of focusing on the time you put in and watch results appear over time."
        />
        <FeatureCard
          imageSrc={statistic}
          title="Statistics to Track Your Progress"
          description="Gain valuable insights into your productivity journey. Track and analyze your sessions to make sure to put in some minutes every day."
        />
        <FeatureCard
          imageSrc={Diary}
          title="Diary of Time Spent"
          description="Capture your productivity story with Minutes' built-in diary feature. Record what you've accomplished and outline what's next on your agenda."
        />
        <FeatureCard
          imageSrc={Run}
          title="Track Progress"
          description="Monitor your progress live with the  built-in tracking. Gain insights into your time utilization, refining your strategy for continuous productivity improvement."
        />
      </SimpleGrid>
    </Box>
  );
};

export default HomeSection3;
