import React, { useState } from 'react';
import { Box, Heading, Input, Text, Button, Flex } from '@chakra-ui/react';
import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';

const Category = () => {
  const [categories] = useState([
    { id: 1, name: 'Category 1' },
    { id: 2, name: 'Category 2' },
  ]);

  const handleEditCategory = id => {
    // Logic to edit category based on its ID
    console.log(`Editing category with ID: ${id}`);
  };

  const handleDeleteCategory = id => {
    // Logic to delete category based on its ID
    console.log(`Deleting category with ID: ${id}`);
  };

  return (
    <>
      <WebNav />
      <MobileNav />

      <Flex direction="column" alignItems="center" mt={8}>
        <Heading as="h1" color="#B7b0dd" mb={6}>
          Category
        </Heading>

        {/* Input for adding a new category */}
        <Box mb={4}>
          <Input
            placeholder="Add Category"
            borderColor="#B7b0dd"
            color="#B7b0dd"
          />
          <Button
            mt={2}
            variant="outline"
            borderColor="#B7b0dd"
            color="#B7b0dd"
          >
            Add
          </Button>
        </Box>

        {/* Display existing categories */}
        <Box width="50%" borderWidth="1px" borderRadius="md" overflow="hidden">
          <Box bg="#B7b0dd" p={4} color="white" fontWeight="bold">
            Categories
          </Box>
          <Box p={4} borderBottom="2px solid #B7b0dd">
            {categories.map((category, index) => (
              <Box
                key={category.id}
                borderBottom="1px solid"
                p={4}
                borderBottomColor="gray.300"
              >
                <Flex justify="space-between" alignItems="center" mb={1}>
                  <Text color="#B7b0dd" fontWeight="medium">
                    {category.name}
                  </Text>
                  <Box>
                    <Button
                      mr="5px"
                      onClick={() => handleEditCategory(category.id)}
                      variant="outline"
                      borderColor="#B7b0dd"
                      color="#B7b0dd"
                      size="sm"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDeleteCategory(category.id)}
                      variant="outline"
                      borderColor="#B7b0dd"
                      color="#B7b0dd"
                      size="sm"
                    >
                      Delete
                    </Button>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Category;
