import React, { useEffect, useState } from 'react';
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Center,
  Divider,
  Container,
} from '@chakra-ui/react';
import WebNav from '../Components/WebNav';
import MobileNav from '../Components/MobileNav';
import BlogCard from '../Components/BlogCard';

import Blog2 from '../Assets/blog2.jpg';

import Clock from '../Components/Clock';
import BlogData from '../data/BlogData.json';
import { requestForToken } from '../utils/firbase';

import { useAddDeviceToken } from '../utils/auth.api';
import { toast } from 'react-toastify';

const Blog = () => {
  const AddDeviceToken = useAddDeviceToken();
  const fetchToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        await AddDeviceToken.mutateAsync({ deviceTokens: token });
      }
    } catch (error) {
      const checkIsWarn = localStorage.getItem('tokenIssueWarned');
      if (!checkIsWarn) {
        localStorage.setItem('tokenIssueWarned', true);
        toast.warn(
          `Notifications isn't enabled for this browser, Please enable it manually in Settings`
        );
      }
      console.error('Error fetching device token:', error);
    }
  };
  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <>
      <WebNav />
      <MobileNav />
      <Container maxW="80%">
        <Box
          mt={8}
          borderRadius="7px"
          p={{ base: 2, md: 0 }}
          justifyItems="center"
          alignItems="center"
        >
          <Flex
            direction="column"
            alignItems="center"
            mt={{ base: '20px', md: '0px' }}
          >
            <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
              Blogs
            </Text>
            <Divider
              mb={3}
              mt={-2}
              borderWidth="2px"
              borderColor="#9990ce"
              w={{ base: '25%', md: '8%' }}
            />
          </Flex>

          <SimpleGrid
            mt={8}
            mb={{ base: '250px', md: '200px' }}
            columns={{ base: 1, md: 3 }}
            gap={2}
            color="white"
          >
            {BlogData.map(blogPost => (
              <Center>
                <BlogCard
                  imageSrc={Blog2}
                  id={blogPost.id}
                  title={blogPost.title}
                  description={blogPost.description}
                />
              </Center>
            ))}
          </SimpleGrid>
        </Box>
        <Clock />
      </Container>
    </>
  );
};

export default Blog;
