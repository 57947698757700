import React, { useState, useEffect, useRef } from 'react';

import {
  Box,
  Flex,
  IconButton,
  Text,
  FormControl,
  FormLabel,
  VStack,
  Button,
  Icon,
} from '@chakra-ui/react';

import { BsClock } from 'react-icons/bs';

import { IoMdAdd } from 'react-icons/io';

import StopWatch from '../Components/stopWatch';
import { useMediaQuery } from '@chakra-ui/react';

import { useBreakpointValue } from '@chakra-ui/react';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

import WebNav from '../Components/WebNav';
import MobileNav from '../Components/MobileNav';
import { useGetAllOfCurrentUser } from '../utils/user.api';
import GoalModal from '../Components/GoalModel';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

const LandingPage = () => {
  

  const [showContent, setShowContent] = useState(false);

  const [isSmallerScreen] = useMediaQuery('(max-width: 48em)');

  const [isOpen, setIsOpen] = useState(false);
  const [isGoalModelOpen, setIsGoalModelOpen] = useState(false);

  // edit post states
  const swiperRef = useRef(null);

  const [activeGoalId, setActiveGoalId] = useState(null);
  const [activeGoalName, setActiveGoalName] = useState('');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // Function to navigate to the previous slide
  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSlideChange = swiper => {
    const activeIndex = swiper.activeIndex;
    const currentActiveGoal = getAllGoalsOfCurrenrUserMutation[activeIndex];
    const currentActiveGoalId = currentActiveGoal?.id;
    const currectActiveGoalName = currentActiveGoal?.goalName;

    setActiveGoalId(currentActiveGoalId); // Set the activeGoalId in the state
    setActiveGoalName(currectActiveGoalName);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenGoalModel = () => {
    setIsGoalModelOpen(true);
  };

  const handeCloseGoalModel = () => {
    setIsGoalModelOpen(false);
  };

  // Adjust the breakpoint as needed
  const handleResize = () => {
    if (window.innerWidth > 768) {
      setShowContent(false);
    }
  };

  const { data: getAllGoalsOfCurrenrUserMutation } = useGetAllOfCurrentUser();

  // Effect to add window resize event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const initialActiveGoal = getAllGoalsOfCurrenrUserMutation?.[0];
    const initialActiveGoalId = initialActiveGoal?.id;
    const initialActiveGoalName = initialActiveGoal?.goalName;
    if (!activeGoalId && !activeGoalName) {
      setActiveGoalId(initialActiveGoalId);
      setActiveGoalName(initialActiveGoalName);
    }
  }, [getAllGoalsOfCurrenrUserMutation, activeGoalId, activeGoalName]);

  return (
    <Box justifyContent="center" alignItems="center" position="relative">
      {/* Navbar - Desktop View */}
      <WebNav />

      {/* Bottom Bar - Mobile View */}
      <MobileNav setShowContent={setShowContent} showContent={showContent} />

      {/* Main Content */}

      <Box justifyContent="center" mt="1em">
        <Flex
          direction="column"
          position="fixed"
          zIndex="3"
          bottom={isMobile ? '6.5em' : '4.7em'}
          right="1.2em"
          onClick={handleOpenModal}
        >
          <Text fontSize="14px" fontWeight="bold" color="#978fc9">
            Goal Timer
          </Text>

          <Flex
            direction="column"
            position="fixed"
            zIndex="3"
            bottom={isMobile ? '4em' : '1.5em'}
            right="2em"
            onClick={handleOpenModal}
          >
            <IconButton
              icon={
                <BsClock color="#978fc9" boxSize={isMobile ? '30px' : '90px'} />
              }
              size="xl"
              fontSize={isMobile ? '40px' : '50px'}
              colorScheme="#b7b0dd"
              aria-label="Clock Icon"
              borderRadius="full"
              onClick={handleOpenModal}
              boxShadow="rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"
            />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          position="fixed"
          zIndex="3"
          bottom={isMobile ? '10.5em' : '9.5em'}
          right="1.5em"
          onClick={() => {
            handleOpenGoalModel();
          }}
        >
          <Text fontSize="14px" fontWeight="bold" color="#978fc9">
            Add Goal
          </Text>

          <Flex
            direction="column"
            borderRadius="50%"
            border="3px solid #978fc9"
            position="fixed"
            zIndex="3"
            bottom={isMobile ? '8em' : '6.3em'}
            right="2em"
            onClick={() => {
              handleOpenGoalModel();
            }}
            _hover={{
              boxShadow: '0 0 50px #ccc',
            }}
          >
            <IconButton
              icon={
                <IoMdAdd color="#978fc9" boxSize={isMobile ? '30px' : '70px'} />
              }
              size="xl"
              fontSize={isMobile ? '33px' : '42px'}
              colorScheme="#b7b0dd"
              aria-label="Clock Icon"
              onClick={() => {
                handleOpenGoalModel();
              }}

              // boxShadow="rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"
            />
          </Flex>
        </Flex>
        <StopWatch
          isOpen={isOpen}
          onClose={handleCloseModal}
          goalId={activeGoalId}
          goalName={activeGoalName}
        />

        {/* goalModel */}
        <GoalModal isOpen={isGoalModelOpen} onClose={handeCloseGoalModel} />

        <Flex
          justifyContent="center"
          alignItems="center"
          mt={isSmallerScreen ? '3em' : ''}
        >
          <Box defaultIndex={[0]} width={{ base: '90%', md: '50%' }}>
            {getAllGoalsOfCurrenrUserMutation?.length > 0 ? (
              <Swiper
                ref={swiperRef}
                navigation={false}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                onSlideChange={handleSlideChange}
                initialSlide={0}
              >
                {getAllGoalsOfCurrenrUserMutation?.map((goal, index) => (
                  <Box key={goal.id} p="5px">
                    <SwiperSlide key={index}>
                      <Flex justifyContent="center" alignItems="center">
                        <FormControl
                          mb={4}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* <IoIosArrowBack/> */}
                          <Icon
                            as={IoIosArrowBack}
                            boxSize={10}
                            color="#B7b0dd"
                            cursor="pointer"
                            onClick={goToPrevSlide}
                          />
                          <FormLabel
                            fontWeight="bold"
                            color="#B7b0dd"
                            m="0"
                            fontSize="30px"
                          >
                            {goal?.goalName}
                          </FormLabel>

                          <Icon
                            as={IoIosArrowForward}
                            boxSize={10}
                            color="#B7b0dd"
                            cursor="pointer"
                            onClick={goToNextSlide}
                          />
                        </FormControl>
                      </Flex>
                      <Flex pb={4} justifyContent="center" alignItems="center">
                        <Box>
                          <Text color="#b7b0dd">Goal Notes:</Text>
                          <ul>
                            {goal?.goalDays?.length > 0 ? (
                              goal.goalDays.map((note, index) => (
                                <React.Fragment key={index}>
                                  {note.note && (
                                    <>
                                      <Text
                                        width="100%"
                                        color="#b7b0dd"
                                        fontWeight="bold"
                                      >
                                        {note.date}:
                                      </Text>
                                      <Text width="100%" color="#b7b0dd">
                                        {note.note}
                                      </Text>
                                    </>
                                  )}
                                </React.Fragment>
                              ))
                            ) : (
                              <Text color="#b7b0dd">
                                No goal notes available
                              </Text>
                            )}
                          </ul>
                        </Box>
                      </Flex>
                    </SwiperSlide>
                  </Box>
                ))}
              </Swiper>
            ) : (
              <VStack spacing={4} align="center">
                <Text
                  textAlign="center"
                  color="#b7b0dd"
                  fontSize="18px"
                  fontWeight="bold"
                >
                  {isMobile
                    ? 'Click on the Plus  bottom  to add goals.'
                    : 'Please Add new Goal'}
                </Text>
                {!isMobile && (
                  <Button
                    colorScheme="purple"
                    onClick={() => {
                      handleOpenGoalModel();
                    }}
                  >
                    Add Goal
                  </Button>
                )}
              </VStack>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default LandingPage;
