import React, { useState } from 'react';

import { Flex, IconButton ,Text} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import StopWatch from '../Components/stopWatch';
import { BsClock } from 'react-icons/bs';
import { BiPlusCircle } from 'react-icons/bi';
import GoalModal from './GoalModel';
import { IoMdAdd } from "react-icons/io";

const Clock = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [isGoalModelOpen, setIsGoalModelOpen] = useState(false);

  const handleOpenGoalModel = () => {
    setIsGoalModelOpen(true);
  };

  const handeCloseGoalModel = () => {
    setIsGoalModelOpen(false);
  };

  return (
    <>
      
      <Flex
          direction="column"
          position="fixed"
          zIndex="3"
          bottom={isMobile ? '6.5em' : '4.7em'}
          right="1.2em"
          onClick={handleOpenModal}
        >
          <Text
         
         fontSize="14px"
         fontWeight="bold"
            color="#978fc9"
          >
            Goal Timer
          </Text>

          <Flex
            direction="column"
            position="fixed"
            zIndex="3"
            bottom={isMobile ? '4em' : '1.5em'}
            right="2em"
            onClick={handleOpenModal}
          >
            <IconButton
              icon={
                <BsClock color="#978fc9" boxSize={isMobile ? '30px' : '90px'} />
              }
              size="xl"
              fontSize={isMobile ? '40px' : '50px'}
              colorScheme="#b7b0dd"
              aria-label="Clock Icon"
              borderRadius="full"
              onClick={handleOpenModal}
              boxShadow="rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"
            />

            {/* <StopWatch
              isOpen={isOpen}
              onClose={handleCloseModal}
              goalId={activeGoalId}
              goalName={activeGoalName}
            /> */}
          </Flex>
        </Flex>


        <Flex
          direction="column"
          position="fixed"
        
          zIndex="3"
          bottom={isMobile ? '10.5em' : '9.5em'}
          right="1.5em"
          onClick={() => {
            handleOpenGoalModel();
          }}
          
        >
          <Text
          
          fontSize="14px"
          fontWeight="bold"
            color="#978fc9"
          >
            Add Goal
          </Text>
         
          <Flex
            direction="column"
            borderRadius="50%"
            // borderColor="white" 
            border="3px solid #978fc9" 
            // bgColor="black"
            // width="200px"
            position="fixed"
            zIndex="3"
            bottom={isMobile ? '8em' : '6.3em'}
            right="2em"
            onClick={() => {
              handleOpenGoalModel();
            }}
            _hover={{
              boxShadow: '0 0 50px #ccc'
            }}
            
          >
            <IconButton
              icon={
                <IoMdAdd
                  color="#978fc9"
                  boxSize={isMobile ? '30px' : '70px'}
                />
              }
              size="xl"
              fontSize={isMobile ? '33px' : '42px'}
              colorScheme="#b7b0dd"
              aria-label="Clock Icon"
              // borderRadius="full"
              onClick={() => {
                handleOpenGoalModel();
              }}
              
              // boxShadow="rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"
            />
          </Flex>
        </Flex>

      <StopWatch isOpen={isOpen} onClose={handleCloseModal} />
      <GoalModal isOpen={isGoalModelOpen} onClose={handeCloseGoalModel} />
    </>
  );
};

export default Clock;
