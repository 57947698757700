import React from 'react';
import Chart from 'react-apexcharts';

const formatTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
};

const LineChartComponent = ({ goalData }) => {
 
  if (!goalData || !goalData.goalDays) {
    return <div>No data available for the goal</div>;
  }

  // Aggregate time spent for the same date
  const dateMap = {};
  goalData.goalDays.forEach(({ date, timeSpent }) => {
    if (date in dateMap) {
      dateMap[date] += timeSpent;
    } else {
      dateMap[date] = timeSpent;
    }
  });

  const data = Object.entries(dateMap).map(([date, timeSpent]) => ({
    x: date,
    y: timeSpent,
  }));

  const tooltipData = data.map(entry => ({
    x: entry.x,
    y: entry.y,
    formattedTime: formatTime(entry.y), // Add formatted time here
  }));

  const options = {
    chart: {
      id: 'basic-line-chart',
      toolbar: {
        show: false, // Hide the toolbar
      },
    },

    xaxis: {
      type: 'category',
      categories: data.map(entry => entry.x),
    },
    yaxis: {
      title: {
        text: 'Time Spent',
      },
    },
    colors: ['#B7b0dd'],
    markers: {
      size: 5, // Set the size of the markers
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div>${tooltipData[dataPointIndex].formattedTime}</div>`;
      },
    },
  };

  return (
    <Chart
      options={options}
      series={[
        {
          name: 'Time Spent',
          data: data.map(entry => ({
            x: entry.x,
            y: formatTime(entry.y), // Use formatTime function here
          })), // Use formatTime function here
          // Set the markers for each data point
          markers: {
            size: 5,
          },
        },
      ]}
      type="line"
      width={'100%'}
      height={'100%'}
    />
  );
};

export default LineChartComponent;
