import React from 'react';
import { useParams } from 'react-router-dom';
import { Text, Image, Box, Heading, Container } from '@chakra-ui/react';
import WebNav from '../Components/WebNav';
import MobileNav from '../Components/MobileNav';

import Blog2 from '../Assets/blog2.jpg';
import BlogData from '../data/BlogData.json';

const BlogDetail = () => {
  const { id } = useParams();

  const selectedBlogPost = BlogData.find(post => post.id === id);

  if (!selectedBlogPost) {
    return (
      <>
        <WebNav />
        <MobileNav />
        <Text mt={8} fontSize="xl">
          Blog post not found.
        </Text>
      </>
    );
  }

  const { title, description } = selectedBlogPost;

  return (
    <>
      <WebNav />
      <MobileNav />

      <Container maxW="80%">
        <Box
          display="flex"
          flexDirection="column"
          mt={8}
          p={4}
          mb={{ base: '100px', md: '100px' }}
          alignItems="center"
        >
          <Image
            src={Blog2}
            alt={title}
            width="50%"
            h="30%"
            borderRadius="md"
            mb={4}
          />

          <Heading as="h1" size="lg" mb={5} mt={2}>
            {title}
          </Heading>

          <Text fontSize="lg" color="gray.600">
            {description}
          </Text>
        </Box>
      </Container>
    </>
  );
};

export default BlogDetail;
