import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  FormControl,
  FormLabel,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { useGetAllOfCurrentUser } from '../utils/user.api';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import CustomCalendar from '../Components/CustomCalendar';
import WebNav from '../Components/WebNav';
import MobileNav from '../Components/MobileNav';

import Clock from '../Components/Clock';

const Calender = () => {
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);

  const getAllOfCurrentUserMutation = useGetAllOfCurrentUser();
  const { data: getAllOfCurrentUser } = getAllOfCurrentUserMutation;

  useEffect(() => {
    if (getAllOfCurrentUser && getAllOfCurrentUser.length > 0) {
      setCurrentGoalIndex(prevIndex =>
        Math.min(prevIndex, getAllOfCurrentUser.length - 1)
      );
    }
  }, [getAllOfCurrentUser]);

  const handleGoalChange = direction => {
    if (direction === 'forward') {
      setCurrentGoalIndex(prevIndex =>
        prevIndex < (getAllOfCurrentUser?.length || 0) - 1
          ? prevIndex + 1
          : prevIndex
      );
    } else {
      setCurrentGoalIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <Box position="relative">
      {/* Navbar - Desktop View */}
      <WebNav />

      {/* Bottom Bar - Mobile View */}
      <MobileNav />

      <Flex
        direction="column"
        alignItems="center"
        mt={{ base: '45px', md: '0px' }}
      >
        <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
          Calender
        </Text>
        <Divider
          mb={3}
          mt={-2}
          borderWidth="2px"
          borderColor="#9990ce"
          w={{ base: '25%', md: '10%' }}
        />
      </Flex>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        mt="10px"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" just="center">
          <FormControl display="flex" alignItems="center">
            <IconButton
              icon={<IoIosArrowBack size={25} />}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              color="#B7b0dd"
              onClick={() => handleGoalChange('backward')}
            />
            <FormLabel fontWeight="bold" color="#B7b0dd" m="0">
              {getAllOfCurrentUser?.[currentGoalIndex]?.goalName}
            </FormLabel>
            <IconButton
              icon={<IoIosArrowForward size={25} />}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              color="#B7b0dd"
              onClick={() => handleGoalChange('forward')}
            />
          </FormControl>
        </Box>
        <CustomCalendar goalData={getAllOfCurrentUser?.[currentGoalIndex]} />
      </Box>

      {/* Main Content */}

      <Clock />
    </Box>
  );
};

export default Calender;
