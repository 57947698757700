// import { QueryCache } from "react-query";

import { QueryCache } from '@tanstack/react-query';

// const apiURL = "http://localhost:3100";
const apiURL = 'https://minutesapi.theminutesapp.com';

const queryCache = new QueryCache({
  onError: error => {
    console.log(error);
  },
  onSuccess: data => {
    console.log(data);
  },
});

async function client(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const token = localStorage.getItem('token');
  const config = {
    method: data ? 'POST' : 'GET',
    body: data instanceof FormData ? data : JSON.stringify(data),
    headers: {
      Authorization:
        token || data?.forgetToken ? `${token || data?.forgetToken}` : '',
      ...(!data || data instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),

      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async response => {
      // const body = response.json();
      if (response.status === 401) {
        localStorage.clear();
        queryCache.clear();
        return await Promise.reject({ message: 'Please re-authenticate.' });
      }

      if (response.ok) {
        const jsonData = await response.json(); // Await the JSON parsing
        return jsonData;
      } else {
        const jsonData = await response.json(); // Await the JSON parsing
        return await Promise.reject(jsonData);
      }
    });
}

export { client };
