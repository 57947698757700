import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  IconButton,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
} from '@chakra-ui/react';
import { CgProfile } from 'react-icons/cg';
import { IoIosList } from 'react-icons/io';
import { BsBook } from 'react-icons/bs';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { ImStatsBars } from 'react-icons/im';
import { SettingsIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { CgStack } from 'react-icons/cg';
// import { FaListCheck } from "react-icons/fa6";
import { MdChecklist } from 'react-icons/md';
import { FaThList } from 'react-icons/fa';
import { useQueryClient } from '@tanstack/react-query';
import StopWatch from '../Components/stopWatch';
import { useGetLoggedInUser, useUpdateProfile } from '../utils/user.api';
import Logo from '../Assets/logo1.png';

const WebNav = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getLoginedUser = useGetLoggedInUser();

  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <Flex
        justify="space-between"
        bgColor={'#B7b0dd'}
        p={4}
        display={{ base: 'none', md: 'flex' }}
      >
        <Box display="flex">
          <Flex
            mr="5em"
            direction="row"
            bg="transparent"
            cursor="pointer"
            alignItems="center"
            mb="7px"
            color="#FFFFFF"
            onClick={() => navigate('/dashboard')}
          >
            {/* <CgStack size="25px" style={{ transform: 'rotate(90deg)' }} />
             */}
            <Image src={Logo} w={9} h={9} mr={2} />
            <Text fontSize={'30px'} fontWeight={'bold'}>
              Minutes
            </Text>
          </Flex>
          {/* Left side */}
          <Flex>
            <Button
              mr={2}
              // variant="solid"
              borderColor="#B7b0dd"
              borderWidth="2px"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              color="#FEFCFF"
              fontWeight={active === '/statistics' ? 'bold' : 'medium'}
              leftIcon={
                <ImStatsBars color="#FEFCFF" stroke="white" strokeWidth="0.5" />
              }
              onClick={() => {
                navigate('/statistics');
              }}
            >
              statistics
            </Button>
            <Button
              mr={2}
              variant="solid"
              bg="transparent"
              borderColor="#B7b0dd"
              borderWidth="2px"
              _hover={{ bg: 'transparent' }}
              color="#FEFCFF"
              fontWeight={active === '/calender' ? 'bold' : 'medium'}
              leftIcon={
                <AiTwotoneCalendar
                  color="#FEFCFF"
                  stroke="#FEFCFF"
                  strokeWidth="1"
                />
              }
              onClick={() => {
                navigate('/calender');
              }}
            >
              Calendar
            </Button>
            <Button
              mr={2}
              borderColor="#B7b0dd"
              borderWidth="2px"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              color="#FEFCFF"
              fontWeight={active === '/diary' ? 'bold' : 'medium'}
              leftIcon={
                <BsBook color="#FEFCFF" stroke="#FEFCFF" strokeWidth="1" />
              }
              onClick={() => {
                navigate('/diary');
              }}
            >
              Diary
            </Button>
            <Button
              mr={2}
              borderColor="#B7b0dd"
              borderWidth="2px"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              color="#FEFCFF"
              fontWeight={active === '/dairy' ? 'bold' : 'medium'}
              leftIcon={
                <IoIosList color="#FEFCFF" stroke="#FEFCFF" strokeWidth="1" />
              }
              onClick={() => {
                navigate('/MyGoals');
              }}
            >
              My Goals
            </Button>
          </Flex>
        </Box>

        {/* Right side */}
        <Flex>
          <Button
            borderColor="#B7b0dd"
            borderWidth="2px"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            color="#FEFCFF"
            fontWeight={active === '/setting' ? 'bold' : 'medium'}
            leftIcon={
              <SettingsIcon color="#FEFCFF" stroke="#FEFCFF" strokeWidth="1" />
            }
            mr={2}
            onClick={() => {
              navigate('/setting');
            }}
          >
            Settings
          </Button>

          <Menu>
            <MenuButton
              size="md"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              aria-label="Profile Icon"
              as={IconButton}
              icon={
                getLoginedUser?.data?.user?.image ? (
                  <Image
                    src={getLoginedUser?.data?.user?.image}
                    alt="User Profile"
                    borderRadius="full"
                    boxSize="35px"
                    border="2px solid white"
                  />
                ) : (
                  <CgProfile
                    color="#FEFCFF"
                    stroke="#FEFCFF"
                    strokeWidth="1"
                    size="20px"
                  />
                )
              }
            ></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  navigate('/profile');
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  queryClient.invalidateQueries(['token']);
                  // pauseStopwatch();

                  navigate('/');
                }}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
          {/* <IconButton
            icon={
              <CgProfile
                color="#FEFCFF"
                stroke="#FEFCFF"
                strokeWidth="1"
                size="20px"
              />
            }
            size="md"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            aria-label="Profile Icon"
            onClick={() => {
              navigate('/profile');
            }}
          /> */}
        </Flex>
      </Flex>
    </>
  );
};

export default WebNav;
