import React from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
} from '@chakra-ui/react';

import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';

import { useGetAllOfCurrentUser } from '../utils/user.api';

const Setting = () => {
  const getAllOfCurrentUserMutation = useGetAllOfCurrentUser();
  const { data: getAllGoalsOfCurrenrUser } = getAllOfCurrentUserMutation;

  const extractGoalTime = () => {
    if (getAllGoalsOfCurrenrUser) {
      const totalGoalTimeArray = [];

      getAllGoalsOfCurrenrUser.forEach(goal => {
        const { goalName, goalDays } = goal;
        let totalGoalTime = 0;

        goalDays.forEach(day => {
          const { timeSpent } = day;
          totalGoalTime += timeSpent;
        });

        totalGoalTimeArray.push({ goalName, totalGoalTime });
      });

      return totalGoalTimeArray;
    }

    return [];
  };

  const totalGoalTimeArray = extractGoalTime();

  const formatTime = seconds => {
    if (seconds < 60) {
      return `${seconds}s`;
    } else if (seconds >= 60 && seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${remainingMinutes}m`;
    }
    return '';
  };

  return (
    <>
      <WebNav />
      <MobileNav />

      <Box ml="1.5rem">
        <Heading as="h1" color="#B7b0dd" fontSize="30px" ml="30px">
          User Goals
        </Heading>

        <Container maxW={{ md: '60%' }}>
          <TableContainer mt="20px">
            <Table variant="simple">
              <TableCaption>Goal Names and Total Time Spent</TableCaption>
              <Thead bg={'#9990ce'}>
                <Tr>
                  <Th color="white">#</Th>
                  <Th color="white">Goal Name</Th>
                  <Th color="white">Total Time Spent</Th>
                </Tr>
              </Thead>
              <Tbody>
                {totalGoalTimeArray.map((goalData, index) => (
                  <Tr
                    h={'50px'}
                    key={index}
                    bg={index % 2 === 0 ? '' : '#f7f7f7'}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{goalData.goalName}</Td>
                    <Td>{formatTime(goalData.totalGoalTime)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    </>
  );
};

export default Setting;
