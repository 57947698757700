import React from 'react';
import { Flex, Heading, Text, Image } from '@chakra-ui/react';

const FeatureCard = ({ imageSrc, title, description, customBoxShadow }) => {
  const defaultBoxShadow = `
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
`;

  const boxShadow = customBoxShadow ? customBoxShadow : defaultBoxShadow;
  return (
    <Flex
      p={{ base: 0, md: 2 }}
      borderRadius="5px"
      flexDirection="column"
      // bg="#9990ce"
      color="black"
      // color="black"
      // h="100%"
      alignItems="center"
    >
      <Image src={imageSrc} maxW="70px" maxH="70px" />

      <Heading as="h1" size="lg" pt={3} textAlign="center">
        {title}
      </Heading>

      <Text p={4} textAlign="center" pt={3} fontFamily="Open Sans">
        {description}
      </Text>
    </Flex>
  );
};

export default FeatureCard;
