import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import 'react-phone-input-2/lib/style.css';
import Icon from '../Assets/icons/clock-solid.svg';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginAdmin, useCreateUser } from '../utils/auth.api';
import Logo from '../Assets/logo1.png';

const Auth = () => {
  const location = useLocation();

  const [signClicked, setSignClicked] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [registerData, setRegisterData] = useState({
    fullName: '',
    userName: '',
    email: '',
    PhoneNumber: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setLoginData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleChangeRegisteration = e => {
    const { name, value } = e?.target;
    setRegisterData(prevData => ({ ...prevData, [name]: value }));
    setIsTyping(true);
  };

  const isPasswordValid = () => {
    const { password } = registerData;
    // Add your password validation criteria here
    const minLength = 6;
    const hasNumber = /\d/.test(password);
    const hasCapitalLetter = /[A-Z]/.test(password);

    return password.length >= minLength && hasNumber && hasCapitalLetter;
  };

  const navigate = useNavigate();

  const loginMutation = useLoginAdmin();
  const { isLoading } = loginMutation;

  const registrationMutation = useCreateUser();
  const { isLoading: RegIsLoading } = registrationMutation;

  const handleLogin = async e => {
    e.preventDefault();

    if (!loginData?.email || !loginData?.password) {
      toast.error('Please Enter Credentials');
      // Check if email or password is empty and return without making the API call
      return;
    }

    const formData = {
      email: loginData?.email,
      password: loginData?.password,
    };

    try {
      await loginMutation.mutateAsync(formData);
      navigate('/dashboard');

      // setLoginData?.email(""); // Clear email input
      // setPassword(""); // Clear password input

      toast.success('Logged In Successfully!');
    } catch (error) {
      toast.error('invalid Credentials', error);
    }
  };

  const handleRegistration = async e => {
    e.preventDefault();

    if (!registerData?.email || !registerData?.password) {
      toast.error('Please Enter Credentials');
      return;
    }

    if (registerData.password !== registerData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    const formData = {
      fullName: registerData?.fullName,
      userName: registerData?.userName,
      email: registerData?.email,
      phoneNumber: registerData?.PhoneNumber,
      password: registerData?.password,
    };

    try {
      await registrationMutation.mutateAsync(formData);
      setSignClicked(!signClicked);
      setRegisterData({
        fullName: '',
        userName: '',
        email: '',
        PhoneNumber: '',
        password: '',
      });
      toast.success('Rigistered Success');
    } catch (error) {
      toast.error('Registration Failed', error);
    }
  };

  useEffect(() => {
    if (location.state === 1) {
      setSignClicked(true);
    } else if (location.state === 2) {
      setSignClicked(false);
    }
  }, [location.state]);

  return (
    <Box
      display={'flex'}
      pb={5}
      flexDirection={signClicked ? 'row-reverse' : 'row'}
      transition="flex-direction 23s ease-in-out"
    >
      {isSmallScreen && (
        <Box p={'20px'} position={'fixed'} w={'100%'}>
          <Flex
            gap={2}
            align={'center'}
            cursor="pointer"
            onClick={() => {
              navigate('/');
            }}
          >
            <Image src={Icon} alt="icon" w={10} h={10} />
            <Text fontSize={'30px'} fontWeight={'bold'}>
              Minutes
            </Text>
          </Flex>
        </Box>
      )}
      {!isSmallScreen && (
        <Box bgColor={'#B7b0dd'} width={'35%'} p={'40px'} h={'100vh'}>
          {signClicked === false && (
            <Flex
              gap={2}
              align={'center'}
              position={'fixed'}
              cursor="pointer"
              onClick={() => {
                navigate('/');
              }}
            >
              <Image color="white" src={Logo} alt="icon" w={10} h={10} />
              <Text fontSize={'30px'} fontWeight={'bold'} color="white">
                Minutes
              </Text>
            </Flex>
          )}
          <Flex
            direction={'column'}
            justify={'center'}
            align={'center'}
            height={'97%'}
            gap={'30px'}
            color={'white'}
            mt={'10%'}
          >
            <Heading fontWeight={'600'} fontSize={'42px'}>
              {signClicked ? 'Hello, Friend!' : 'Welcome Back'}
            </Heading>
            <Text width={'62%'} textAlign={'center'} fontWeight={'400'}>
              {signClicked
                ? 'Enter your personal details and start journey with us'
                : 'To keep connected with us please login with your personal info'}
            </Text>
            <Button
              width={'45%'}
              borderRadius={'20px'}
              bg={'transparent'}
              border={'2px solid white'}
              padding={'20px'}
              color={'white'}
              variant={'outlined'}
              _hover={{ padding: '22px' }}
              onClick={() => {
                if (signClicked) {
                  setSignClicked(false);
                } else {
                  setSignClicked(true);
                  // navigate("/landing")
                }
              }}
            >
              {signClicked ? 'SIGN UP' : 'SIGN IN'}
            </Button>
          </Flex>
        </Box>
      )}
      <Box p={'40px'} h={'100vh'} w={isSmallScreen ? '100%' : '65%'}>
        {signClicked && !isSmallScreen && (
          <Flex
            gap={2}
            align={'center'}
            mb={'30px'}
            position={'fixed'}
            cursor="pointer"
            onClick={() => {
              navigate('/');
            }}
          >
            <Image src={Icon} alt="icon" w={10} h={10} />
            <Text fontSize={'30px'} fontWeight={'bold'}>
              Minutes
            </Text>
          </Flex>
        )}
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          //  gap={'20px'}
          //   border={'2px solid black'}

          color={'#B7B0DD'}
          mt={'10%'}
        >
          <Heading
            fontWeight={'700'}
            fontSize={'36px'}
            color={'#3b3b3b'}
            mt={'30px'}
          >
            {signClicked ? '' : 'Hi!'} Welcome{signClicked ? '!' : ''}
          </Heading>

          <Heading
            fontSize={'24px'}
            mb={'30px'}
            mt={'15px'}
            textAlign={'center'}
          >
            {signClicked
              ? 'Please enter your details'
              : 'Please register in below'}
          </Heading>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={isSmallScreen ? '100%' : '70%'}
            height={'100%'}
          >
            {signClicked ? (
              <FormControl mt={isSmallScreen ? '30px' : '70px'}>
                <Input
                  mb={'25px'}
                  placeholder="Email"
                  name="email"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  value={loginData?.email}
                  onChange={handleChange}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />
                <Input
                  placeholder="Password"
                  type="password"
                  name="password"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  value={loginData?.password}
                  onChange={handleChange}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />
                <Flex justify={'flex-end'} mb={'40px'} mt="10px">
                  <Text
                    textAlign={'end'}
                    cursor={'pointer'}
                    onClick={() => navigate('/forget-password')}
                  >
                    Forgot Password?
                  </Text>
                </Flex>
                <Button
                  borderRadius={'20px'}
                  bg={'#3b3b3b'}
                  color={'white'}
                  width={'100%'}
                  variant={'outlined'}
                  _hover={{ bg: 'black' }}
                  onClick={handleLogin}
                  isDisabled={
                    !loginData?.email || !loginData?.password || isLoading
                  }
                >
                  {isLoading ? <Spinner size="xs" /> : 'Sign in'}
                </Button>
                {isSmallScreen && (
                  <Text
                    textDecoration={'underline'}
                    mt="10px"
                    textAlign={'end'}
                    onClick={() => setSignClicked(!signClicked)}
                  >
                    {signClicked ? 'Sign Up?' : 'Sign In?'}
                  </Text>
                )}
              </FormControl>
            ) : (
              <FormControl>
                <Input
                  mb={'15px'}
                  placeholder="Please Enter Your Email"
                  name="email"
                  value={registerData?.email}
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  onChange={handleChangeRegisteration}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />
                <Input
                  mb={'15px'}
                  placeholder="Full Name"
                  name="fullName"
                  value={registerData?.fullName}
                  border="3px solid #B7b0dd"
                  onChange={handleChangeRegisteration}
                  borderRadius={'20px'}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />

                <Input
                  placeholder="Password"
                  name="password"
                  value={registerData?.password}
                  onChange={handleChangeRegisteration}
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  type="password"
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />

                {isTyping && !isPasswordValid() && (
                  <Text color="red" ml="10px">
                    Password must least 6 character, contain number and capital
                  </Text>
                )}
                <Input
                  mb={'15px'}
                  mt="15px"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  type="password"
                  onChange={handleChangeRegisteration}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />
                <Button
                  borderRadius={'20px'}
                  bg={'#3b3b3b'}
                  color={'white'}
                  width={'100%'}
                  variant={'outlined'}
                  _hover={{ bg: 'black' }}
                  isDisabled={
                    !registerData?.email ||
                    !registerData?.password ||
                    RegIsLoading
                  }
                  onClick={handleRegistration}
                >
                  {RegIsLoading ? <Spinner size="xs" /> : 'Sign Up'}
                </Button>
                {isSmallScreen && (
                  <Text
                    textDecoration={'underline'}
                    mt="10px"
                    textAlign={'end'}
                    onClick={() => setSignClicked(!signClicked)}
                  >
                    {signClicked ? 'Sign Up?' : 'Sign In?'}
                  </Text>
                )}
              </FormControl>
            )}
          </Box>
          {/* {signClicked ? (
            ''
          ) : (
            <Text
              mt={'30px'}
              w={isSmallScreen ? '' : '35%'}
              textAlign={'center'}
              mb={isSmallScreen ? '40px' : ''}
            >
              We need permission for the service you use Learn More
            </Text>
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;
