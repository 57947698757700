import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Heading, Text, Image } from '@chakra-ui/react';

const BlogCard = ({ imageSrc, title, description, id }) => {
  const [showFullDescription] = useState(false);

  const limitedDescription = description.split(' ').slice(0, 30).join(' ');

  return (
    <Flex
      //   border="2px solid black"
      borderRadius="5px"
      flexDirection="column"
      color="black"
      height="300px"
      maxWidth="90%"
    >
      <Flex height="60%" position="relative">
        <Image
          src={imageSrc}
          width="100%"
          height="100%"
          objectFit="cover"
          borderRadius="5px 5px 0 0"
        />
      </Flex>

      <Flex direction="column" height="40%" p={4}>
        <Heading as="h1" size="md">
          {title}
        </Heading>

        <Text pt={3} fontFamily="Open Sans">
          {showFullDescription ? description : `${limitedDescription}... `}
          {description.split(' ').length > 20 && (
            <span style={{ color: 'blue', cursor: 'pointer' }}>
              <RouterLink to={`/blog/${id}`}>Read More</RouterLink>
            </span>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};

export default BlogCard;
