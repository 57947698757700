import React, { useState, useEffect } from 'react';
import {
  Flex,
  Button,
  IconButton,
  Stack,
  Heading,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Text,
  Center,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { BsClock } from 'react-icons/bs';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { IoIosStats } from 'react-icons/io';
import { IoIosSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FaBookOpen } from 'react-icons/fa';
import GoalModal from './GoalModel';
import StopWatch from './stopWatch';
import { CgStack } from 'react-icons/cg';
import { useQueryClient } from '@tanstack/react-query';
import { useGetLoggedInUser } from '../utils/user.api';

import { CgProfile } from 'react-icons/cg';
import Logo from '../Assets/logo1.png';

const MobileNav = ({ setShowContent, showContent }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const getLoginedUser = useGetLoggedInUser();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setActive(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <>
      <Flex
        // mr="5em"
        direction="row"
        bg="#b7b0dd"
        display={{ base: 'flex', md: 'none' }}
        cursor="pointer"
        alignItems="center"
        mb="7px"
        color="black"
        p={1}
        borderBottomRadius={2}
        justifyContent="space-between"
        position="fixed"
        zIndex="3"
        top={0}
        align={'center'}
        left={0}
        right={0}
        // overflow="hidden"
      >
        <Flex onClick={() => navigate('/dashboard')} align="center">
          <Image src={Logo} w={7} h={7} mr={2} />
          <Text fontSize={'25px'} fontWeight={'bold'} color="white">
            Minutes
          </Text>
        </Flex>

        <Flex>
          <Menu>
            <MenuButton
              size="md"
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              aria-label="Profile Icon"
              as={IconButton}
              icon={
                getLoginedUser?.data?.user?.image ? (
                  <Image
                    src={getLoginedUser?.data?.user?.image}
                    alt="User Profile"
                    borderRadius="full"
                    boxSize="30px"
                    border="2px solid white"
                  />
                ) : (
                  <CgProfile
                    color="#FEFCFF"
                    stroke="#FEFCFF"
                    strokeWidth="1"
                    size="20px"
                  />
                )
              }
            ></MenuButton>
            <MenuList zIndex={3}>
              <MenuItem
                onClick={() => {
                  navigate('/profile');
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  queryClient.invalidateQueries(['token']);

                  navigate('/');
                }}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex
        wrap="wrap"
        justify="space-between"
        p={1}
        zIndex="1"
        display={{ base: 'flex', md: 'none' }}
        position="fixed"
        bottom={0}
        align={'center'}
        left={0}
        right={0}
        overflow="hidden"
        backgroundColor="#FEFCFF"
      >
        <Button
          width="20%"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          fontWeight={active === '/statistics' ? 'bold' : 'medium'}
          color="black"
          onClick={() => {
            navigate('/statistics');
          }}
        >
          <Stack direction="column" alignItems="center" spacing={0}>
            <IoIosStats color="black" boxSize={5} />
            <span style={{ fontSize: '13px' }}>Stats</span>
          </Stack>
        </Button>

        <Button
          width="20%"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          fontWeight={active === '/diary' ? 'bold' : 'medium'}
          color="black"
          onClick={() => {
            navigate('/diary');
          }}
        >
          <Stack direction="column" alignItems="center" spacing={0}>
            <FaBookOpen color="black" boxSize={5} />
            <span style={{ fontSize: '13px' }}>Diary</span>
          </Stack>
        </Button>

        {/* Middle - Clock icon */}
        <IconButton
          width="20%"
          icon={<BsClock color="black" boxSize={5} />}
          size="lg"
          fontSize="4xl"
          colorScheme="#B7b0dd"
          aria-label="Clock Icon"
          // variant="ghost"
          bg="transparent"
          // borderRadius="full"
          onClick={handleOpenModal}
        />

        <StopWatch isOpen={isOpen} onClose={handleCloseModal} />

        <Button
          width="20%"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          fontWeight={active === '/calender' ? 'bold' : 'medium'}
          color="black"
          onClick={() => {
            navigate('/calender');
          }}
        >
          <Stack direction="column" alignItems="center" spacing={0}>
            <AiTwotoneCalendar color="black" boxSize={5} />
            <span style={{ fontSize: '13px' }}>Calender</span>
          </Stack>
        </Button>

        <Button
          width="20%"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          fontWeight={active === '/setting' ? 'bold' : 'medium'}
          color="black"
          onClick={() => {
            navigate('/setting');
          }}
        >
          <Stack direction="column" alignItems="center" spacing={0}>
            <IoIosSettings color="black" boxSize={5} />
            <span style={{ fontSize: '13px' }}>Setting</span>
          </Stack>
        </Button>
      </Flex>
    </>
  );
};

export default MobileNav;
