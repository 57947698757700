import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import Auth from './pages/Auth';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ForgetPassword from './pages/ForgetPassword';
import LandingPage from './pages/LandingPage';
import Dairy from './pages/dairy';
import Home from './pages/home';
import Setting from './pages/setting';
import Category from './pages/Category';
import Statics from './pages/statics';
import Calender from './pages/calendar';
import Profile from './pages/profile';
import LeaderBoard from './pages/leaderBoard';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import GoalsLists from './pages/GoalsLists';
import { useQuery } from '@tanstack/react-query';
import { TimerProvider } from './Components/TimerContext';
import Notification from './Components/notication';
import PWAAlert from './Components/PWAAlert';
import { isMobile } from 'react-device-detect';
// import {firebaseConfig, getTokenn} from "./utils/firbase"
// import { initializeApp } from 'firebase/app';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [operatingSystem, setOperatingSystem] = useState('');
  const [isStandalone, setIsStandalone] = useState(false);

  const { data: token } = useQuery(['token'], () =>
    localStorage.getItem('token')
  );

  const checkPlatform = () => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes('iphone') || platform.includes('ipad')) {
      setOperatingSystem('iOS');
    } else {
      setOperatingSystem('Android');
    }
  };

  const detectStandaloneMode = () => {
    // Check if the browser supports matchMedia
    if ('matchMedia' in window) {
      const mediaQuery = '(display-mode: standalone)';
      const mediaQueryList = window.matchMedia(mediaQuery);

      if (mediaQueryList.matches) {
        setIsStandalone(true);
      } else {
        setIsStandalone(false);
      }

      // Listen for changes in display mode
      mediaQueryList.addListener(event => {
        setIsStandalone(event.matches);
      });
    }

    // Check if the browser supports navigator.standalone
    if ('navigator' in window && 'standalone' in window.navigator) {
      setIsStandalone(window.navigator.standalone === true ? true : false);
    }

    window.addEventListener('appinstalled', evt => {
      setIsStandalone(true);
    });
  };

  useEffect(() => {
    if (!isStandalone && !localStorage.getItem('isAlerted') && isMobile) {
      onOpen();
    } else {
      onClose();
    }
  }, [isStandalone]);

  useEffect(() => {
    detectStandaloneMode();
    checkPlatform();
  }, []);

  if (token === undefined) {
    return <Spinner size="xl" />;
  }

  return (
    <TimerProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Notification />
          <PWAAlert
            isOpen={isOpen}
            onClose={onClose}
            operatingSystem={operatingSystem}
          />
          <Routes>
            {token ? (
              <>
                <Route path="/dashboard/*" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogDetail />} />
                <Route path="/setting/*" element={<Setting />} />
                <Route path="/goalLists/*" element={<GoalsLists />} />
                <Route path="/category/*" element={<Category />} />
                <Route path="/statistics/*" element={<Statics />} />
                <Route path="/diary/*" element={<Dairy />} />
                <Route path="/MyGoals/*" element={<LandingPage />} />
                <Route path="/calender/*" element={<Calender />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/leaderboard/*" element={<LeaderBoard />} />
                <Route path="/*" element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/auth/*" element={<Auth />} />
                <Route path="/" element={<Home />} />
                <Route path="/*" element={<Navigate to="/" />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </TimerProvider>
  );
}

export default App;
