import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Icon from '../Assets/icons/clock-solid.svg';
import Lock from '../Assets/icons/lock-solid.svg';
import { useNavigate } from 'react-router-dom';
import {
  useForGetPassword,
  useVerifyOtp,
  useResetPassword,
} from '../utils/auth.api';

const ForgetPassword = () => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();

  const [showEmailInput, setShowEmailInput] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showResetInput, setResestInput] = useState(false);
  const [forgetPasswordToken, setForgetPasswordToken] = useState('');

  const [forgetPasswordData, setForgetPasswordData] = useState({
    email: '',
    otp: '',
    password: '',
  });

  const handleForgetPassword = e => {
    const { name, value } = e.target;
    setForgetPasswordData(prevData => ({ ...prevData, [name]: value }));
  };

  const forgetPasswordMutation = useForGetPassword();
  const { isLoading } = forgetPasswordMutation;

  const verifyOtpMutation = useVerifyOtp();
  const { isLoading: verifyOtpLoading } = verifyOtpMutation;

  const resetPasswordMutation = useResetPassword();
  const { isLoading: resetPasswordLoading } = resetPasswordMutation;

  const handleSendOtp = async e => {
    if (!forgetPasswordData?.email) {
      toast.error('Please Enter Email');
      // Check if email or password is empty and return without making the API call
      return;
    }

    const formData = {
      email: forgetPasswordData?.email,
    };

    try {
      const response = await forgetPasswordMutation.mutateAsync(formData);

      setShowEmailInput(false);
      setShowOtpInput(true);

      toast.success(response?.message);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleVerifyOtp = async e => {
    if (!forgetPasswordData?.email && !forgetPasswordData?.otp) {
      toast.error('Please Enter Email & Otp');

      return;
    }

    const formData = {
      email: forgetPasswordData?.email,
      otp: forgetPasswordData?.otp,
    };

    try {
      const response = await verifyOtpMutation.mutateAsync(formData);

      setForgetPasswordToken(response?.token);

      setShowEmailInput(false);
      setShowOtpInput(false);
      setResestInput(true);

      toast.success(response?.message);
    } catch (error) {
      toast.error('Otp Failed', error);
    }
  };

  const handleResetPassword = async e => {
    if (!forgetPasswordData?.password) {
      toast.error('Please Enter Password');
      // Check if email or password is empty and return without making the API call
      return;
    }

    const formData = {
      password: forgetPasswordData?.password,
      forgetToken: forgetPasswordToken,
    };

    try {
      const response = await resetPasswordMutation.mutateAsync(formData);

      setShowEmailInput(true);
      setShowOtpInput(false);
      setResestInput(false);

      navigate('/auth');

      toast.success(response?.message);
    } catch (error) {
      toast.error('reset password failed', error);
    }
  };

  return (
    <Box display={'flex'} transition="flex-direction 23s ease-in-out">
      <Box p={'40px'} position={'fixed'} w={'100%'}>
        <Flex gap={2} align={'center'}>
          <Image src={Icon} alt="icon" w={10} h={10} />
          <Text fontSize={'30px'} fontWeight={'bold'}>
            Minutes
          </Text>
        </Flex>
      </Box>

      <Box
        w={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        //  gap={'20px'}
        //   border={'2px solid black'}
        color={'#B7B0DD'}
        mt={'7%'}
      >
        {showOtpInput && (
          <>
            <Heading
              fontWeight={'700'}
              fontSize={'46px'}
              color={'#3b3b3b'}
              mt={isSmallScreen ? '80px' : '30px'}
            >
              Enter your Otp
            </Heading>
          </>
        )}

        {showEmailInput && (
          <>
            <Heading
              fontWeight={'700'}
              fontSize={'46px'}
              color={'#3b3b3b'}
              mt={isSmallScreen ? '80px' : '30px'}
            >
              Oopps!
            </Heading>

            <Heading
              fontSize={'32px'}
              mb={'40px'}
              mt={'15px'}
              textAlign={'center'}
            >
              I forgot
            </Heading>
          </>
        )}

        {showResetInput && (
          <>
            <Heading
              fontWeight={'700'}
              fontSize={'46px'}
              color={'#3b3b3b'}
              mt={isSmallScreen ? '80px' : '30px'}
            >
              Enter New Password
            </Heading>
          </>
        )}

        <Image src={Lock} alt="lock" w={16} h={16} mb={'20px'} />
        <Text
          mb={'20px'}
          width={isSmallScreen ? '90%' : '30%'}
          textAlign={'center'}
        >
          Enter your email, phone or username and we'll send you a Otp your
          Email to change new password
        </Text>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100%'}
          width={isSmallScreen ? '90%' : '30%'}
        >
          <FormControl>
            {showEmailInput && (
              <>
                <Input
                  mb={'25px'}
                  placeholder="Enter Your  Email"
                  name="email"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  onChange={handleForgetPassword}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />

                <Button
                  borderRadius={'20px'}
                  bg={'#3b3b3b'}
                  color={'white'}
                  width={'100%'}
                  variant={'outlined'}
                  _hover={{ bg: 'black' }}
                  onClick={handleSendOtp}
                  isDisabled={!forgetPasswordData?.email || isLoading}
                >
                  {isLoading ? <Spinner size="xs" /> : 'Send'}
                </Button>
              </>
            )}

            {showOtpInput && (
              <>
                <Input
                  mb={'25px'}
                  placeholder="Enter your Otp"
                  name="otp"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  onChange={handleForgetPassword}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />

                <Button
                  borderRadius={'20px'}
                  bg={'#3b3b3b'}
                  color={'white'}
                  width={'100%'}
                  variant={'outlined'}
                  _hover={{ bg: 'black' }}
                  onClick={handleVerifyOtp}
                  isDisabled={
                    !forgetPasswordData?.email ||
                    !forgetPasswordData?.otp ||
                    verifyOtpLoading
                  }
                >
                  {verifyOtpLoading ? <Spinner size="xs" /> : 'Verify Otp'}
                </Button>
              </>
            )}

            {showResetInput && (
              <>
                <Input
                  mb={'25px'}
                  placeholder="Enter New Password"
                  type="password"
                  name="password"
                  border="3px solid #B7b0dd"
                  borderRadius={'20px'}
                  onChange={handleForgetPassword}
                  css={{
                    '&::placeholder': { color: '#B7b0dd', fontWeight: '600' },
                  }}
                  variant={'outlined'}
                />

                <Button
                  borderRadius={'20px'}
                  bg={'#3b3b3b'}
                  color={'white'}
                  width={'100%'}
                  variant={'outlined'}
                  _hover={{ bg: 'black' }}
                  onClick={handleResetPassword}
                  isDisabled={
                    !forgetPasswordData?.password || resetPasswordLoading
                  }
                >
                  {resetPasswordLoading ? (
                    <Spinner size="xs" />
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </>
            )}

            <Text
              textDecoration={'underline'}
              mt="10px"
              textAlign={'end'}
            ></Text>
          </FormControl>
        </Box>

        <Text mt={'30px'} textAlign={'center'} mb={isSmallScreen ? '30px' : ''}>
          Don't have an account?{' '}
          <Text
            textDecoration={'none'}
            cursor="pointer"
            onClick={() => {
              navigate('/auth', { state: 2 });
            }}
          >
            Sign Up
          </Text>
        </Text>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
