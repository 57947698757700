import React from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Text,
  Grid,
  GridItem,
  Image,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import {
  FaFacebookF,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaGithub,
} from 'react-icons/fa';
import { useBreakpointValue } from '@chakra-ui/react';
// import Logo from "../Assets/clock.jpg"
import logo from '../Assets/logo.png';

import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const currentYear = new Date().getFullYear();
  return (
    <Box mt={10} textAlign="center" py={8} p={5}>
      <Grid   templateColumns={isMobile ? '1fr' : 'repeat(4, 1fr)'}
        gap={6}
        justifyContent="center">
        <GridItem w="100%" h="100%" color="black">
          <Flex
            flexDirection="column"
            h="200px"
            alignItems="center"
            justifyContent="space-between"
            pt="10px"
            
          >
            <Box display="flex" justifyContent="center" justifyItems="center">
              <Image src={logo} w={9} h={9} />
              <Heading ml="10px" as="h1" size="lg">
                Minutes
              </Heading>
            </Box>

            <Heading as="h4" fontSize="15px">
              ©️ {currentYear} Developed By:  Gellf
            </Heading>
          </Flex>
        </GridItem>
        <GridItem w="100%" h="100%" color="black">
          <Flex flexDirection="column" justifyContent="space-between" h="200px">
            <Heading as="h4" fontSize="20px">
              About us
            </Heading>

            <Text color="#c1bdbd">About Minutes</Text>
            <Text color="#c1bdbd">Contact us</Text>
            <Text color="#c1bdbd">Features</Text>
            <Text color="#c1bdbd">Careers</Text>
          </Flex>
        </GridItem>
        <GridItem w="100%" h="100%" color="black">
          <Flex flexDirection="column">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              h="200px"
            >
              <Heading as="h4" fontSize="20px">
                Resources
              </Heading>

              <Text color="#c1bdbd">Help center</Text>
              <Text color="#c1bdbd">Book a demo</Text>
              <Text color="#c1bdbd">Server status</Text>
              <Text color="#c1bdbd">Blog</Text>
            </Flex>
          </Flex>
        </GridItem>
      
        <GridItem w="100%" h="100%" color="black">
          <Flex flexDirection="column">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              h="200px"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Heading
                  as="h4"
                  fontSize="20px"
                  display="flex"
                  flexDirection="column"
                >
                  Get in touch
                </Heading>

                <Button
                  size="md"
                  width="80%"
                  mt="10px"
                  onClick={() => {
                    navigate('/auth', { state: 2 });
                  }}
                  colorScheme="black"
                  variant="outline"
                >
                  Sign up!
                </Button>
              </Box>

              {/* <Text>Questions or feedback?</Text> */}
              <Box display="row">
                <IconButton
                  href=""
                  aria-label="Facebook"
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                  }}
                  icon={<FaFacebookF />}
                />

                <IconButton
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                  }}
                  href=""
                  aria-label="Twitter"
                  icon={<FaTwitter />}
                />
                
                <IconButton
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                  }}
                  href=""
                  aria-label="Instagram"
                  icon={<FaInstagram />}
                />
                <IconButton
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                  }}
                  href=""
                  aria-label="Google"
                  icon={<FaGoogle />}
                  mr={4}
                />
                <IconButton
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                  }}
                  href=""
                  aria-label="Linkedin"
                  icon={<FaLinkedin />}
                />
              </Box>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Footer;
