import React, { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { FaBell, FaPlus } from 'react-icons/fa';

import { FaFlag } from 'react-icons/fa';

import { useMediaQuery } from '@chakra-ui/react';

import { FiFilePlus } from 'react-icons/fi';
import { FaList } from 'react-icons/fa';

import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';

import GoalModal from '../Components/GoalModel';
import { useAddDeviceToken } from '../utils/auth.api';
import { requestForToken } from '../utils/firbase';
import { toast } from 'react-toastify';

const Setting = () => {
  const AddDeviceToken = useAddDeviceToken();
  const navigate = useNavigate();
  // Define box width based on screen size
  const boxWidth = useBreakpointValue({ base: '90%', sm: '70%', md: '500px' });

  const [isSmallerScreen] = useMediaQuery('(max-width: 48em)');

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const fetchToken = async () => {
    if (!('Notification' in window)) {
      console.log('Browser does not support desktop notification');
    } else {
      window.Notification.requestPermission();
    }
    try {
      const token = await requestForToken();
      if (token) {
        await AddDeviceToken.mutateAsync({ deviceTokens: token })
          .then(res => {
            toast.success('Notifications Enabled for this browser!');
          })
          .catch(err => {
            toast.warn(err?.message);
          });
      }
    } catch (error) {
      toast.warn(error?.message);
    }
  };

  return (
    <>
      <WebNav />
      <MobileNav />

      <Box ml="1.5rem">
        <Heading as="h1" color="#B7b0dd" fontSize="30px" ml="30px">
          Settings
        </Heading>
      </Box>

      <VStack alignItems="center" justifyContent="center" h="100vh">
        <Box
          border="2px solid #B7b0dd"
          width={boxWidth}
          borderRadius="11px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70px"
          mb="20px"
          cursor="pointer"
          _hover={{ bg: '#F6F6F6' }}
          onClick={() => {
            navigate('/goalLists');
          }}
        >
          <Flex
            justifyContent="space-between"
            p="4em"
            alignItems="center"
            width="100%"
          >
            <Box>
              {' '}
              <Heading as="h1" fontSize="20px" color="#B7b0dd">
                Goals Lists
              </Heading>
            </Box>
            <Box>
              {' '}
              <FaList size="30px" color="#8c5ec9" />
            </Box>
          </Flex>
        </Box>

        <Box
          border="2px solid #B7b0dd"
          width={boxWidth}
          borderRadius="11px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70px"
          mb="20px"
          cursor="pointer"
          _hover={{ bg: '#F6F6F6' }}
          onClick={() => {
            navigate('/MyGoals');
          }}
        >
          <Flex
            justifyContent="space-between"
            p="4em"
            alignItems="center"
            width="100%"
          >
            <Box>
              {' '}
              <Heading as="h1" fontSize="20px" color="#B7b0dd">
                My Goals
              </Heading>
            </Box>
            <Box>
              {' '}
              <FaFlag size="30px" color="#8c5ec9" />
              {/* <i class="fa-solid fa-bullseye" style={{color:"red"}}></i> */}
            </Box>
          </Flex>
        </Box>

        <Box
          border="2px solid #B7b0dd"
          width={boxWidth}
          borderRadius="11px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70px"
          mb="20px"
          cursor="pointer"
          _hover={{ bg: '#F6F6F6' }}
          onClick={() => {
            navigate('/profile');
          }}
        >
          <Flex
            justifyContent="space-between"
            p="4em"
            alignItems="center"
            width="100%"
          >
            <Box>
              {' '}
              <Heading as="h1" fontSize="20px" color="#B7b0dd">
                Manage Profile
              </Heading>
            </Box>
            <Box>
              {' '}
              <CgProfile size="30px" color="#8c5ec9" />
            </Box>
          </Flex>
        </Box>

        <Box
          border="2px solid #B7b0dd"
          width={boxWidth}
          borderRadius="11px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70px"
          mb="20px"
          cursor="pointer"
          _hover={{ bg: '#F6F6F6' }}
          onClick={handleOpenModal}
        >
          <Flex
            justifyContent="space-between"
            p="4em"
            alignItems="center"
            width="100%"
          >
            <Box>
              {' '}
              <Heading as="h1" fontSize="20px" color="#B7b0dd">
                Add Goal
                <GoalModal isOpen={isOpen} onClose={handleCloseModal} />
              </Heading>
            </Box>
            <Box>
              {' '}
              {/* <BsClipboardPlusFill />  */}
              {isSmallerScreen ? (
                <FiFilePlus size="30px" color="#8c5ec9" />
              ) : (
                <FaPlus size="30px" color="#8c5ec9" />
              )}
            </Box>
          </Flex>
        </Box>

        <Box
          border="2px solid #B7b0dd"
          width={boxWidth}
          borderRadius="11px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70px"
          mb="20px"
          cursor="pointer"
          _hover={{ bg: '#F6F6F6' }}
          onClick={fetchToken}
        >
          <Flex
            justifyContent="space-between"
            p="4em"
            alignItems="center"
            width="100%"
          >
            <Box>
              {' '}
              <Heading as="h1" fontSize="20px" color="#B7b0dd">
                Enable Notifications
              </Heading>
            </Box>
            <Box>
              {' '}
              {/* <BsClipboardPlusFill />  */}
              {isSmallerScreen ? (
                <FaBell size="30px" color="#8c5ec9" />
              ) : (
                <FaBell size="30px" color="#8c5ec9" />
              )}
            </Box>
          </Flex>
        </Box>
      </VStack>
    </>
  );
};

export default Setting;
