// HeroSection.js
import React from 'react';
import { Box, Flex, Heading, Text, Image, SimpleGrid } from '@chakra-ui/react';
import HeroSctionImage from '../Assets/heroSectionClock.png';

const HeroSection = () => {
  return (
    <Box bg="#9990ce" minH="80vh">
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        gap={0}
        h="90vh"
        color="white"
        // mb={{base:2,md:2}}
        // textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
      >
        {/* Left Section */}
        <Flex
          direction="column"
          justify="center"
          p={8}
          pt={{ base: '120px', md: '0px' }}
        >
          <Heading as="h1" size="xl" alignItems="center">
            Welcome to the Minutes App
          </Heading>
          <Text fontSize="lg" fontFamily="Open Sans" fontWeight="bold">
            Unlock Your Productivity with a Gentle Approach to Success!
          </Text>
        </Flex>

        {/* Right Section  */}
        <Flex justifyContent="center" alignItems="center">
          <Image
            src={HeroSctionImage}
            maxW={{ base: '200px', md: '400px' }}
            maxH="400px"
          />
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default HeroSection;
