import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  IconButton,
  Flex,
  Divider,
} from '@chakra-ui/react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { useGetAllOfCurrentUser } from '../utils/user.api';
import LineChartComponent from '../Components/lineChart';
import MobileNav from '../Components/MobileNav';
import WebNav from '../Components/WebNav';

import Clock from '../Components/Clock';

const Statics = () => {
  const [active, setActive] = useState('');
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);

  const getAllOfCurrentUserMutation = useGetAllOfCurrentUser();
  const { data: getAllGoalsOfCurrenrUser } = getAllOfCurrentUserMutation;

  useEffect(() => {
    if (getAllGoalsOfCurrenrUser && getAllGoalsOfCurrenrUser.length > 0) {
      // Ensure currentGoalIndex stays within bounds
      setCurrentGoalIndex(prevIndex =>
        Math.min(prevIndex, getAllGoalsOfCurrenrUser.length - 1)
      );
    }
  }, [getAllGoalsOfCurrenrUser]);

  const handleSetActive = () => {
    setActive('statistics');
  };

  const handleGoalChange = direction => {
    if (direction === 'forward') {
      setCurrentGoalIndex(prevIndex =>
        prevIndex < (getAllGoalsOfCurrenrUser.length || 0) - 1
          ? prevIndex + 1
          : prevIndex
      );
    } else {
      setCurrentGoalIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <>
      <WebNav active={active} setActive={handleSetActive} />
      <MobileNav />

      <Box position="relative" h="350px">
        <Flex
          direction="column"
          alignItems="center"
          mt={{ base: '45px', md: '0px' }}
        >
          <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
            Statistics
          </Text>
          <Divider
            mb={3}
            mt={-2}
            borderWidth="2px"
            borderColor="#9990ce"
            w={{ base: '25%', md: '10%' }}
          />
        </Flex>

        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          mt="10px"
          alignItems="center"
          h={'100%'}
        >
          <Box display="flex" alignItems="center" just="center">
            <FormControl display="flex" alignItems="center">
              <IconButton
                icon={<IoIosArrowBack size={25} />}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
                color="#B7b0dd"
                onClick={() => handleGoalChange('backward')}
              />
              <FormLabel fontWeight="bold" color="#B7b0dd" m="0">
                {getAllGoalsOfCurrenrUser?.[currentGoalIndex]?.goalName}
              </FormLabel>
              <IconButton
                icon={<IoIosArrowForward size={25} />}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
                color="#B7b0dd"
                onClick={() => handleGoalChange('forward')}
              />
            </FormControl>
          </Box>
          <Box width={{ base: '90%', md: '30%' }} h={'100%'}>
            <LineChartComponent
              goalData={getAllGoalsOfCurrenrUser?.[currentGoalIndex]}
            />
          </Box>
        </Box>
        <Clock />
      </Box>
    </>
  );
};

export default Statics;
