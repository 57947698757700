import React from 'react';
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Image,
  Center,
  Divider,
} from '@chakra-ui/react';
import FeatureCard from './ExperienceCard';
import Easy from '../Assets/easy.png';
import Triger from '../Assets/stress-test.png';
import Goal from '../Assets/goal.png';
import statistic from '../Assets/rating.png';
import Diary from '../Assets/planner.png';
import Run from '../Assets/run.png';
import Signin from "../Assets/sign-in.png"
import Rocket from "../Assets/rocket.png"
import waiting from "../Assets/waiting-room.png"

const HomeSection5 = () => {
  return (
    <Box mt={8} borderRadius="7px"  p={{base:2, md:0}} justifyItems="center" alignItems="center">
      <Flex direction="column" alignItems="center">
        <Text color="#9990ce" fontSize="3xl" fontWeight="bold">
          Ready to Experience Gentle Success ?
        </Text>
        <Divider
          mb={3}
          mt={-2}
         
          borderWidth="2px"
          borderColor="#9990ce"
          w="49%"
        />
      </Flex>

      <SimpleGrid mt={8} columns={{ base: 1, md: 3 }} gap={2} color="white">
        <FeatureCard
          imageSrc={Signin}
          customBoxShadow={' 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'}
          title="Sign Up"
          description="Sign up for Minutes now and embark on a journey of productivity that aligns with your pace. Progress is achieved by consistent effort, even if it's just five minutes a day."
        />
        <FeatureCard
          imageSrc={Rocket}
          customBoxShadow={'  0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'}
          title="Getting Started"
          description="
          To begin, reflect on what you've always wanted to achieve and what activities you wish to prioritize. This may involve career advancement, educational pursuits, personal development, or creative endeavors. Add them to the Minutes’ List of Goals. Next, identify tasks you've been procrastinating on, those stubborn undertakings that seem to resist completion, such as financial planning, healthcare, or home organization. Add them also."
        />
        <FeatureCard
          imageSrc={waiting}
          customBoxShadow={'  0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'}
          title="What are you waiting for?    "
          description="Once you've identified the overarching goal, start the timer and dive into focused work. A tiny step today will give you a surge of confidence and self-belief. And once you start, the desire to continue will likely become your driving force.

          So, what are you waiting for? Sign up, choose a goal, start the timer, and let Minutes guide you toward unlocking your true potential—one focused minute at a time. Achieve success without the stress—because every minute counts"
        />
      </SimpleGrid>
    </Box>
  );
};

export default HomeSection5;
